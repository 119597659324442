import React, { Component } from 'react';
import 'antd/dist/antd.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Alert,
  UncontrolledTooltip,
  } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import AddLocation from './addLocation.js'
import {connect} from 'react-redux';
import '../stylesheet/navigationbar.css';
import PropTypes from 'prop-types';
import {logoutUser} from '../actions/authActions';
import SearchByCity from './SearchByCity.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

/* SearchByCity component is search engine that queries google maps API to find city coordinates */
class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      visibleDisconnect: true,
      visibleConnect: true,
    };

    this.toggle = this.toggle.bind(this);
    this.displayFavorite = this.displayFavorite.bind(this);
    this.onDismissDisconnect = this.onDismissDisconnect.bind(this);
    this.onDismissConnect = this.onDismissConnect.bind(this);
  }

  toggle() {
     this.setState({
       isOpen: !this.state.isOpen,
     });
   }


   onDismissDisconnect() {
    this.setState({ visibleDisconnect: false });
   }

   onDismissConnect() {
    this.setState({ visibleConnect: false });
   }


   displayFavorite() {
      this.props.displayFavoriteParent();
    }

    onLogoutClick = () => {
      this.props.logoutUser()
    }


  render() {
    const isAuthenticated = this.props.isAuthenticated;
    const user = this.props.user;
    return (

    <div style={{position : "absolute", width:"100%"}}>
    {  isAuthenticated ?
      <div>
        <Navbar className="navBarStyle" light expand="md">
          <NavbarBrand href="/" id="navBarBrandStyle">Dark Sky Map</NavbarBrand>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="locateMe" id="locateMeToolTip" onClick={() => this.props.geoLocateMe()}/>
          <UncontrolledTooltip placement="bottom" target="locateMeToolTip">Locate your position</UncontrolledTooltip>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link to="/" className="homeLink">Home</Link>
              </NavItem>
              <NavItem>
                <AddLocation modalFromClick={this.props.modalFromClick} clickLatLng={this.props.clickLatLng} refreshMarker={this.props.refreshMarker}/>
              </NavItem>
              <NavItem>
                <button className="favorisLink" onClick={()=>this.displayFavorite()}>Favorites</button>
              </NavItem>
              <NavItem>
                <button className="favorisLink" onClick={()=>this.onLogoutClick()}>Sign out</button>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <SearchByCity />
        <Alert color="success" isOpen={this.state.visibleConnect} toggle={this.onDismissConnect}>
         You are connected {user.name}
        </Alert>

      </div>
      :
      <div>
        <Navbar className="navBarStyle" light expand="md">
          <NavbarBrand href="/" id="navBarBrandStyle">Dark Sky Map</NavbarBrand>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="locateMe" id="locateMeToolTip" onClick={() => this.props.geoLocateMe()}/>
          <UncontrolledTooltip placement="bottom" target="locateMeToolTip">Locate your position</UncontrolledTooltip>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link to="/" className="homeLink">Home</Link>
              </NavItem>
              <NavItem>
                <Link to="/signin" className="signInLink">Sign in</Link>
              </NavItem>
              <NavItem>
                <Link to="/signup" className="signUpLink">Sign up</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <SearchByCity />
        <Alert color="warning" isOpen={this.state.visibleDisconnect} toggle={this.onDismissDisconnect}>
         You are disconnected
        </Alert>
      </div>
    }
    </div>

    )
  }
}

NavigationBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
}

/* Redux function that establishes correpondance between states in store and properties of presentation component */
/* This redux function collects information located in store which has in turn been collected from the respective reducers */
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
})


/* This function is accessible via a property in the presentation component. */
/* This function send information to respective redux reducer */
/*  geoLocateMe is a function props used to trigger geolocation of user in map */
/* via geoLocationReducer. */
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    geoLocateMe: function() {
      dispatch({
        type: 'geoLocateMeTrigger'
      })
    },
  }
}

/* creation of container component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationBar);
