import React from 'react';
import { Modal, Form, Input, Radio, DatePicker, Select, Icon, Tooltip } from 'antd';
import 'antd/dist/antd.css';

const FormItem = Form.Item;

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    //This function pre-fill the latitude/longitude input fields with the coordinate captured from the click
    componentDidUpdate(prevProps) {
      if (this.props.clickLatLng !== prevProps.clickLatLng) {
        this.props.form.setFieldsValue({latitude: this.props.clickLatLng.lat});
        this.props.form.setFieldsValue({longitude: this.props.clickLatLng.lng})
      }
    };


    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      const dateFormat = 'DD/MM/YYYY';
      const Option = Select.Option;


      return (
        <Modal
          clickLatLng={this.props.clickLatLng}
          visible={visible}
          title="Add a new observation location"
          okText="Create observation location"
          cancelText="Back"
          onCancel={onCancel}
          onOk={onCreate}
        >



          <Form className="textAddLocation" layout="vertical">

            <FormItem label="Name of the location">
              {getFieldDecorator('locationName', {
                rules: [{ required: true, message: `Please enter name of location` }],
              })(
                <Input />
              )}
            </FormItem>

            <FormItem label="Location category">

              {getFieldDecorator('locationCategory', {
                initialValue: 'outdoor',
              })(
                <Radio.Group>
                  <Radio value="outdoor">Outdoor</Radio>
                  <Radio value="gite">BnB</Radio>
                  <Radio value="observatoire">Observatory</Radio>
                  <Radio value="observatoire&gite">BnB & Observatory</Radio>
                </Radio.Group>
              )}
            </FormItem>

            <FormItem
              label="Observation date">
              {getFieldDecorator('observationDate', {
                rules: [{ required: true, message: `Please enter date of your observation` }],
              })(
                <DatePicker format={dateFormat} />
              )}
            </FormItem>

            <FormItem label="Latitude (ex. 48.879684)">
              {getFieldDecorator('latitude', {
                rules: [{ required: true, message: `Please enter latitude of location` }],
              })(
                <Input />
              )}
            </FormItem>

            <FormItem label="Longitude (ex 2.308955)">
              {getFieldDecorator('longitude', {
                rules: [{ required: true, message: `Please enter longitude of location` }],
              })(
                <Input />
              )}
            </FormItem>

            <FormItem label="Horizon Clearance">
              {getFieldDecorator("isSouthernHorizonClear", {
                rules: [{ required: true, message: `Please indicate clearance of horizon` }],
              })(
                <Select placeholder="Please select your answer">
                  <Option value="Good Clearance">Good Clearance</Option>
                  <Option value="Partial Clearance">Partial Clearance</Option>
                  <Option value="Poor Clearance">Poor Clearance</Option>
                </Select>
              )}
            </FormItem>

            <FormItem label="Bortle Scale">
              {getFieldDecorator('bortleScale', {
                rules: [
                  { required: true, message: `Please enter Bortle scale value` },
                ],
              })(
                <Select placeholder="Please select your answer">
                  <Option value="C1 (Ciel excellent)">C1 (Excellent dark-sky site)</Option>
                  <Option value="C2 (Ciel vraiment noir)">C2 (Typical truly dark site)</Option>
                  <Option value="C3 (Ciel rural)">C3 (Rural sky)</Option>
                  <Option value="C4 (Transition rural-urbain)">C4 (Rural/suburban transition)</Option>
                  <Option value="C5 (Ciel péri-urbain)">C5 (Suburban sky)</Option>
                  <Option value="C6 (Ciel de banlieue)">C6 (Bright suburban sky)</Option>
                  <Option value="C7 (Transition banlieue-ville)">C7 (Suburban/urban transition)</Option>
                  <Option value="C8 (Ciel de ville)">C8 (City sky)</Option>
                  <Option value="C9 (Ciel de centre-ville)">C9 (Inner-city sky)</Option>
                </Select>
              )}
            </FormItem>

            <FormItem label="Observation category">
              {getFieldDecorator("observationCategory", {
                initialValue: "Planetary & Lunar Observation",
              })(
                <Radio.Group>
                  <Radio value="Planetary & Lunar Observation">Planetary & Lunar Observation</Radio>
                  <Radio value="Deep Sky Observation">Deep Sky Observation</Radio>
                </Radio.Group>
              )}
            </FormItem>

            <FormItem label={(
              <span>Sky Quality Meter ?&nbsp;
                <Tooltip title="Luminance of the night sky">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            )}>
              {getFieldDecorator('skyQualityMeter')(<Input type="textarea" />)}
            </FormItem>

            <FormItem label="Accessibility by Car">
              {getFieldDecorator('easeOfAccessibilityByCar')(
                <Radio.Group>
                  <Radio value="true">Yes</Radio>
                  <Radio value="false">No</Radio>
                </Radio.Group>
              )}
            </FormItem>

            <FormItem label="Possibility to Park">
              {getFieldDecorator('parkingAvailability')(
                <Radio.Group>
                  <Radio value="true">Yes</Radio>
                  <Radio value="false">No</Radio>
                </Radio.Group>
              )}
            </FormItem>

            <FormItem label="Electric Power Supply">
              {getFieldDecorator('powerSupplyAvailability')(
                <Radio.Group>
                  <Radio value="true">Yes</Radio>
                  <Radio value="false">No</Radio>
                </Radio.Group>
              )}
            </FormItem>

            <FormItem label="Website">
              {getFieldDecorator('webSite')(
                <Input type="url" placeholder="Website address" />
              )}
            </FormItem>

          </Form>
        </Modal>
      );
    }
  }
);

export default (CollectionCreateForm);
