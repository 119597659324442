import React, {Component} from 'react';
import '../stylesheet/moon.css';
import fullMoon from '../images/moon_phases/full.jpg';
import firstQuarter from '../images/moon_phases/first_quarter.jpg';
import newMoon from '../images/moon_phases/new.jpg';
import thirdQuarter from '../images/moon_phases/third_quarter.jpg';
import waningCrescent from '../images/moon_phases/waning_crescent.jpg';
import waningGibbous from '../images/moon_phases/waning_gibbous.jpg';
import waxingCrescent from '../images/moon_phases/waxing_crescent.jpg';
import waxingGibbous from '../images/moon_phases/waxing_gibbous.jpg';
import { UncontrolledTooltip } from 'reactstrap';

/* Widget that displays phases of moon */
/* As per moon phase we collect a corresponding image amongst those importet */
/* This image is fed to src attribute in image element */
/* We create an array of moon info, on which we map to create a span element for each one */
/* On each span element we apply display block style to start each parameter on new line */
/* This mapped array is fed to tooltip of moon image. */
class Moon extends Component {
  render() {
    
    var moonPic = {};
    if (this.props.moonPhase === 'Full Moon') {
      moonPic = fullMoon;
    } else if (this.props.moonPhase === 'Waning Gibbous') {
      moonPic = waningGibbous;
    } else if (this.props.moonPhase === 'Waning Crescent') {
      moonPic = waningCrescent;
    } else if (this.props.moonPhase === 'Waxing Gibbous') {
      moonPic = waxingGibbous;
    } else if (this.props.moonPhase === 'Waxing Crescent') {
      moonPic = waxingCrescent;
    } else if (this.props.moonPhase === 'New Moon') {
      moonPic = newMoon;
    } else if (this.props.moonPhase === 'Dark Moon') {
      moonPic = newMoon;
    } else if (this.props.moonPhase === '3rd Quarter') {
      moonPic = thirdQuarter;
    } else if (this.props.moonPhase === '1st Quarter') {
      moonPic = firstQuarter;
    }

    var toolTipData = [
      `Lunar Phase: ${this.props.moonPhase}`,
      `Visibility: ${this.props.moonIllumination} %`,
      `Age: ${this.props.ageMoon} days`,
      `Angular Diameter: ${this.props.angularDiameter} degrees`,
      `Earth-Moon Distance: ${this.props.distanceMoonEarth} km`,
    ]

    var toolTipContent = toolTipData.map(function(data,key){
      return (
        <span className="toolTipContentStyle" key={key}>{data}</span>
      );
    })

    return (
      <div>
        <img className="moonPicStyle" id="moonToolTipTarget" src={moonPic} alt="Moon Phase"/>
        <UncontrolledTooltip placement="top" target="moonToolTipTarget">
          {toolTipContent}
        </UncontrolledTooltip>
      </div>
    )
  }
}

export default (Moon);
