import React, {Component} from 'react';
import {Button} from 'reactstrap';
import { Input, DatePicker, InputNumber, message } from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle, faCar} from '@fortawesome/free-solid-svg-icons';
import config from "../config/environment.js";
import '../stylesheet/widgetBlaBlaCar.css';

/*widgetBlaBlaCar component displays a popup after clicking on a car icon in the description component */
/* informations inside the popup are needed to use BlaBlaCar API & display trips in a new window */

class widgetBbc extends Component {
  constructor(props){
    super(props);
    this.state = {
      lat:"" ,
      lon:"" ,
      cityFrom: "",
      cityTo:"",
      startDate: '',
      radiusFrom: 1,
      radiusTo: 1,
      seats: 1,
      dateStart: null,
      dateEnd: null,
    }
  }

/*this function closes the popup and open the description component */
  closeWidgetBbc = (data) => {
    this.props.returnToDescriptionFromWidgetBbc(data)
  }


/*this function collects input lat & lon with coordinates from description component*/
  componentDidMount(){
      this.setState({
          lat:this.props.data.latitude,
          lon:this.props.data.longitude,
      })
  }

/*this function allows to delete latitude input*/
  handleChangeValueLat = () => {
    this.setState({
      lat: "",
    })
  }

/*this function allows to delete longitude input*/
  handleChangeValueLon = () => {
    this.setState({
      lon: "",
    })
  }

/*this function captures data from city departure input*/
  onChangeCityFrom = (e) =>{
    this.setState({
      cityFrom: e.target.value,
    })
  }

/*this function captures data from city destination input*/
  onChangeCityTo = (e) =>{
    this.setState({
      cityTo: e.target.value,
    })
  }

/*this function allows users to choose radius from city departure from an input number*/
  onChangeStart = (value1) =>{
    this.setState({
      radiusFrom: value1,
    })
  }

/*this function allows users to choose radius from city destination from an input number*/
  onChangeEnd = (value2) => {
    this.setState({
      radiusTo: value2,
    })
  }

/*this function allows users to choose the number of seats from an input number*/
  onChangeSeats = (value3) => {
    this.setState({
      seats: value3,
    })
  }

/*this function is the date picker for departure day*/
  onDateChangeS = (date, dateString, abc) => {
    this.setState({
      dateStart: dateString
    })
  }

/*this function is the date picker for return day*/
  onDateChangeE = (date, dateString, abc) => {
    this.setState({
      dateEnd: dateString
    })

  }

  error = () => {
    message.error('Please select a valid city name or coordinates.');
  };

  error1 = () => {
    message.error('Please enter your departure city');
  };

  error2 = () => {
    message.error("Please enter your departure and arrival dates");
  };

/* Get trips from blablacar api */
/*If both city name & coordinates are display, user gets a popup alert to choose one of both*/
searchCarTrips = (event, signal) => {
  event.preventDefault();

  var code = event.keyCode || event.which;

/* Allows users to use enter key ou search button*/
  if(code === 13 || signal === 'searchTrips'){
    if(this.state.cityTo !== "" && (this.state.lat !== "" && this.state.lon !== "")){
         this.error();
    } else if(this.state.cityFrom === ""){
      this.error1();
    } else if((this.state.dateStart === null || undefined) || (this.state.dateEnd === null || undefined)){
      this.error2();
    } else {
        fetch(`${config.config.urlFetch}/api/locations/searchTripsBlaBlaCar`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: 'latitude='+this.props.data.latitude+'&longitude='+this.props.data.longitude+'&cityFrom='+this.state.cityFrom+'&cityTo='+this.state.cityTo+'&lat='+this.state.lat+'&lon='+this.state.lon+'&radius_from='+this.state.radiusFrom+'&radius_to='+this.state.radiusTo+'&seats='+this.state.seats+'&db='+this.state.dateStart+'&de='+this.state.dateEnd
        }).then(response => {
          return response.json();
        }).then(data => {
          window.open(data.links._front)
        }).catch(error => {
          console.error(error);
        });
      }
      }
  }

  render (){

    return(

      <div className="popup">
          <div className="headingBBC">
            <FontAwesomeIcon icon={faCar} className="descriptionIconStyle"/>
            <h4 className="location">BlaBlaCar Carpooling</h4>
            <FontAwesomeIcon icon={faTimesCircle} onClick={() => this.closeWidgetBbc(this.props.data)} className="descriptionIconStyle"/>
          </div>
        <div className="body">
            <h6 className="location">Destination: {this.props.data.locationName}</h6>
            <div className="inputBlablacarCityFrom">
              <p className="inputText">Departure city (Name)</p>
              <Input type="text" onChange={this.onChangeCityFrom} onKeyUp={(event)=>((event.keyCode === 13) || (event.which === 13)) && this.searchCarTrips(event, 'searchTrips')} placeholder="Departure City" className="inputWidgetCityFrom"></Input>
            </div>
          <div className="inputBlablacar">
            <div className="inputBlablacar2">
              <p className="inputText">Arrival City (Name)</p>
              <Input type="text"  onChange={this.onChangeCityTo} onKeyUp={(event)=>((event.keyCode === 13) || (event.which === 13)) && this.searchCarTrips(event, 'searchTrips')} placeholder="Arrival City" className="inputWidget"></Input>
            </div>
              <p className="spaceLatLon">OR</p>
            <div className="inputBlablacar2">
              <p className="inputText">Latitude</p>
              <Input type="text" value={this.state.lat} onChange={this.handleChangeValueLat} className="inputWidget"></Input>
              <p className="inputText">Longitude</p>
              <Input type="text" value={this.state.lon} onChange={this.handleChangeValueLon} className="inputWidget"></Input>
            </div>
          </div>
          <div className="inputBlablacar">
            <div className="inputBlablacar2">
              <p className="inputText">Radius (km) - Departure City</p>
              <InputNumber min={1} max={20} defaultValue={1} onChange={this.onChangeStart} />
            </div>
          <div className="inputBlablacar2">
              <p className="inputText">Radius (km) - Arrival City</p>
              <InputNumber min={1} max={20} defaultValue={1} onChange={this.onChangeEnd} />
          </div>
          </div>
          <div className="inputBlablacar">
            <div className="inputBlablacar2">
              <p className="inputText">Outward Journey</p>
              <DatePicker onChange={(date, dateString) => this.onDateChangeS(date, dateString, 1)} />
            </div>
            <div className="inputBlablacar2">
              <p className="inputText">Return Journey</p>
              <DatePicker onChange={(date, dateString) => this.onDateChangeE(date, dateString, 2)} />
            </div>
          </div>
          <div className="inputBlablacar">
            <div className="inputBlablacar2">
              <p className="inputText">Number of Seats</p>
              <InputNumber min={1} max={10} defaultValue={1} onChange={this.onChangeSeats} />
            </div>
          </div>

            <div className="popupDesc">
            <p className="popupLine1">If you are not redirected to a new tab, please disable your pop-up blocker.</p>
            <p className="popupLine2">To disable your pop-up blocker, please go to your browser settings.</p>
            </div>
          <Button outline color="secondary" className="buttonSearch" onClick={(event)=> this.searchCarTrips(event, 'searchTrips')}>Search</Button>

        </div>
      </div>

    )
  }
}

export default (widgetBbc);
