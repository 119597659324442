import React, { Component } from 'react';
import config from '../config/environment';
import { FaRegFrown, FaRegSmile } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faTimesCircle,
  faMapMarker,
  faBed,
  faCar,
  faStar,
  faDirections,
  faTrashAlt,
  faUser,
  faComments
} from '@fortawesome/free-solid-svg-icons';
import { faWaze } from '@fortawesome/free-brands-svg-icons';
import telescope from '../images/telescope.svg';
import {
  Col,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardText,
  Row,
  Button,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import 'antd/dist/antd.css';
import { Comment, Input , Form, Popconfirm, message  } from 'antd';
import { connect } from 'react-redux';
import Rater from './ratingStars.js';

/* Description component displays description concerning a location after cliking on a marker on map */
/* closeComponent function closes description through closeWindow function in parent map component */
class Description extends Component {
  constructor(props) {
    super(props);
    this.closeComponent = this.closeComponent.bind(this);
    this.addFavorite = this.addFavorite.bind(this);
    this.toggleFavoriteDesc = this.toggleFavoriteDesc.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.locationTypeToolTipToggle = this.locationTypeToolTipToggle.bind(this);
    this.transferRatingData = this.transferRatingData.bind(this);
    this.launchIsochrone = this.launchIsochrone.bind(this);

    this.state = {
      weatherDatas: null,
      userId: null,
      isLiked: false,
      toolTipOpenFavorite: false,
      toolTipOpenInfo: false,
      locationTypeToolTip: false,
      uniqueURL: '',
      showRating: false,
      ratingDataState: {},
      comments: [], // List comments by Location
      currentComment: "", // helps to delete comment by comment
      availabilitySendComment: true, // Helper to activate or deactivate send button
      commentNickName: "", // Helper to store nickname value
      commentText: "" // Helper to store textarea value
    };
  }


  componentWillMount() {
    const ctx = this;
    if (ctx.props.userId) {
      fetch(`${config.config.urlFetch}/api/users/favorites`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'userId=' + ctx.props.userId
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          var userFavorites = data.favorite;

          // Check if this location is in the user favorites, if it is, switch to true the isLiked state
          for (var i = 0; i < data.favorite.length; i++) {
            if (data.favorite[i].locationName === ctx.props.data.locationName) {
              ctx.setState({ isLiked: true });
            }
          }

          ctx.setState({ favorites: userFavorites });
        });
    }

    /* This block of code extracts ID of location when it's marker is clicked. */
    /* The id is used to construct the uniqueURL of a given location as a state. */
    if (this.props.data) {
      this.setState({
        uniqueURL: `https://www.darkskymap.com/map/${this.props.data._id}`
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var ctx = this;

    var userIdCopy = {
      ...ctx.props.userId
    };
    var weatherDatasCopy = {
      ...ctx.props.weatherDatas
    };
    if (this.props.weatherDatas !== prevProps.weatherDatas) {
      ctx.setState({ weatherDatas: weatherDatasCopy });
      if (this.props.userId !== prevProps.userId) {
        ctx.setState({ userId: userIdCopy });
      }
    }

    /*acquire updated value after user's rating, unvote if he unvoted*/
    if (
      prevState.ratingDataState.average !== this.state.ratingDataState.average
    ) {
      var ratingDataStateCopy = { ...this.state.ratingDataState };
      this.setState({ ratingDataState: ratingDataStateCopy });
    }
  }

  /**
   * Get async comment data and set it as state comments, and
   * call first render
   */
  getCommentsByLocation(){
    let self = this;

    fetch(`${config.config.urlFetch}/api/comments/location`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'locationId=' + self.props.data._id
    }).then(function (res){
      return res.json();
    }).then(function(data){
      self.setState({ comments: data.comments });
      self.renderComments(self.state.comments);
    });
  }

  postComment(){
    let self = this;

    if (self.props.userId) {

      if(self.state.commentNickName === ""){
        message.error("Add a username");
        return;
      }

      if(self.state.commentText.length < 1){
        message.error("Add a comment");
        return;
      }

      let comment = JSON.stringify({
        "commentText": self.state.commentText,
        "userId": self.props.userId,
        "commentNickName": self.state.commentNickName,
        "locationId": self.props.data._id
      });

      fetch(`${config.config.urlFetch}/api/comments/new`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: comment

      }).then(function (res){

        return res.json();
      }).then(function(){

        message.success("Comment created successfully");
        self.getCommentsByLocation();

        self.setState({
          commentText: "",
          availabilitySendComment: true
        });

      }).catch(error => {
        console.error(error);
        message.error("Something went wrong");
      });
    }else{
      message.error("You should login first.");
    }

  }

  /**
   * Confir deleting comment
   * - Gets the id as state
   * - Makes a fetch DELETE to the backend using the commentId
   * - Refresh the rendering
   */
  confirmDeleteComment(){
    let self = this;

    // The id must exist
    if(this.state.currentComment === "") {
      message.error('Something went wrong')
      return;
    }

    fetch(`${config.config.urlFetch}/api/comments/remove`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'commentId=' + self.state.currentComment
    })
    .then(function (res){
      return res.json();
    })
    .then(function(){
      message.success('Comment deleted');
      self.setState({ currentComment: "" })
      self.getCommentsByLocation();
    })
    .catch(err => {
        console.error("Error deleting the comment" , err);
        message.error('Something went wrong');
    });
  }

  /**
   * Pass the id from the HTML to the state
   * The goal is to bring this id to the confirmation method, to delete comment
   * @param {String} id commentId
   */
  deleteComment(event){

    let parent = event.target.closest(".container-comment");
    if (!parent) return;

    let id = parent.getAttribute("data-id");
    if(!id || typeof id !== "string") return;

    this.setState({
      currentComment: id
    });

  }


  /*fetch to get the initial value of the rating*/
  componentDidMount(props) {
    var ctx = this;

    fetch(`${config.config.urlFetch}/api/locations/getVotes`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: 'locationId=' + this.props.data._id
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        var dataCopy = { ...data };
        ctx.setState({ ratingDataState: dataCopy });
      });

      // Get the comments by location
      this.getCommentsByLocation();
  }

  showModal = data => {
    this.props.toggleForecast(data);
  };

  closeComponent() {
    this.props.closeFunction();
  }

  //Adds or delete a favorite and handle the isLiked state
  addFavorite(userId, locationId) {
    this.props.addFavoriteParent(userId, locationId, this.state.isLiked);
    this.setState({
      isLiked: !this.state.isLiked
    });
  }

  toggleFavoriteDesc() {
    this.setState({
      toolTipOpenFavorite: !this.state.toolTipOpenFavorite
    });
  }

  toggleInfo() {
    this.setState({
      toolTipOpenInfo: !this.state.toolTipOpenInfo
    });
  }

  locationTypeToolTipToggle() {
    this.setState({
      locationTypeToolTip: !this.state.locationTypeToolTip
    });
  }


  /*this function opens the widget blablacar component*/
  openWidgetBbc = data => {
    this.props.toggleWidgetBbc(data);
  };

  transferRatingData(ratingData) {
    var ratingDataCopy = { ...ratingData };
    this.setState({ ratingDataState: ratingDataCopy });
  }

  /* This function launches Isochrone in map component for given location */
  /* and closes description. */
  launchIsochrone(latitude,longitude) {
    if (latitude && longitude) {
      this.props.closeFunction();
      this.props.launchIsochrone(latitude,longitude);
    }
  }

  /**
   * Update globally the data from the input,
   * Also update the send button state
   * @param {DOMEvent} event
   */
  changeCommentText(event){
    let { value } = event.target;
    this.setState({
      commentText: value,
      availabilitySendComment: value.length > 0 ? false : true
    });
  }

  /**
   * Update globally the data from nickname input
   * @param {DOMEvent} event
   */
  changeNickNameComment(event){
    let { value } = event.target;
    this.setState({
      commentNickName: value,
      availabilitySendComment: value.length > 0 ? false : true
    });
  }

  /**
   * Decides whether the icon delete must or not appear over each comment
   * @param {String} userId
   */
  renderDeleteComment(userId){
    let token = localStorage.getItem('jwtToken');

    if(token){

      if(this.props.userId === userId){
        return (
            <Popconfirm
              title="Delete this comment?"
              onConfirm={() => this.confirmDeleteComment()}
              okText="Yes"
              cancelText="No"
            >
              <FontAwesomeIcon
                id="removeComment"
                className="remove-comment"
                icon={faTrashAlt}
                onClick={event => this.deleteComment(event)}
              />
            </Popconfirm>
        );
      }

    }
  }

  /**
   * Render the comments on the screen
   * @param {Array} comments list of all the comments
   */
  renderComments(comments = this.state.comments){
    let self = this;
    let token = localStorage.getItem('jwtToken');

    if(comments.length > 0 ){
      return this.state.comments.map((comment,index) => {
        return (
          <div key={index} className="container-comment" data-id={comment._id}>
            <Comment
              author={<p>{comment.commentNickName}</p>}
              content={<p >{comment.commentText}</p>}
              datetime={<p>{this.cleanDateComment(comment.commentDate)}</p>}
              actions={[
                <span key={"remove-comment-" + index} className={"container-remove-comment remove-item-" + index} data-userid={comment.userId} >
                  { self.renderDeleteComment(comment.userId) }
                </span>
              ]}
            />
          </div>
          );
      })
    }else{
      return (
        <div className="not-comments-container">
          <FontAwesomeIcon className="not-comments" icon={faComments} />
          <p>There aren't comments on this location yet. {(token) ? "Add a new one." : "Sign in"}</p>
        </div>
      )
    }

  }

  /**
   * Clean the date and shape into a structure
   * yyyy-mm-dd hh:mm  i minuscule ou majuscule g global
   * @param {String} date
   */
  cleanDateComment(date){
    if(typeof date !== "string" ||
        date.length < 1 ||
        !new RegExp("T","ig").test(date) ) return "";

    let day = date.split("T")[0];
    let f_hour = date.split("T")[1];
    let hour = f_hour.split(f_hour.slice(-8))[0];

    return day + " " + hour;
  }

  renderAccessibilityIcons(){
    let easeOfAccessibilityByCar;

      if (this.props.data.easeOfAccessibilityByCar === true) {
        easeOfAccessibilityByCar = <FaRegSmile style={{marginLeft: 10, fontSize: 40}}/>
      } else if (this.props.data.easeOfAccessibilityByCar === false) {
        easeOfAccessibilityByCar = <FaRegFrown style={{marginLeft: 10, fontSize: 40}}/>
      }

    let powerSupplyAvailability;

    if (this.props.data.powerSupplyAvailability === true) {
      powerSupplyAvailability = <FaRegSmile style={{marginLeft: 10, fontSize: 40}}/>
    } else if (this.props.data.powerSupplyAvailability === false) {
      powerSupplyAvailability = <FaRegFrown style={{marginLeft: 10, fontSize: 40}}/>
    }

    let parkingAvailability;

    if (this.props.data.parkingAvailability === true) {
      parkingAvailability = <FaRegSmile style={{marginLeft: 10, fontSize: 40}}/>
    } else if (this.props.data.parkingAvailability === false) {
      parkingAvailability = <FaRegFrown style={{marginLeft: 10, fontSize: 40}}/>
    }

    return(
      <div>
        <Row>
          <Col className="parkingColumn" xs="12" lg="6">
            <CardText className="textDetails">Accessibility by Car</CardText>
            <CardText className="smileyIcon">{easeOfAccessibilityByCar}</CardText>
          </Col>
          <Col className="parkingColumn" xs="12" lg="6">
            <CardText className="textDetails">Possibility to Park</CardText>
            <CardText className="smileyIcon">{parkingAvailability}</CardText>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <CardText className="textDetails">Electric Power Supply</CardText>
            <CardText className="smileyIcon">{powerSupplyAvailability}</CardText>
          </Col>
        </Row>
      </div>
    )
  }

  /**
   * Render the input component composed by Nickname and Textarea
   */
  renderCommentForm(){
    const { TextArea } = Input;

    let token = localStorage.getItem('jwtToken')

    if(token){
      return(
        <div className="add-new-comment">
          <Form.Item>
            <Input placeholder="Add your username" maxLength={20} required onChange={event => this.changeNickNameComment(event)} prefix={<FontAwesomeIcon icon={faUser} />} />
            <TextArea rows={4} placeholder="Add a new comment" maxLength={400} allowClear required onChange={event => this.changeCommentText(event)} />
            <Button className="submit-comment" disabled={this.state.availabilitySendComment} htmltype="submit" onClick={() => this.postComment()} type="primary">
              Add Comment
            </Button>
          </Form.Item>
        </div>
      )
    }

  }

  render() {

    // Handle the heart's color depending on the isLiked state
    let colorHeart;
    this.state.isLiked
      ? (colorHeart = {
          color: '#FF5B53',
          cursor: 'Pointer'
        })
      : (colorHeart = {
          color: '#FFFFFF',
          cursor: 'Pointer'
        });


    if (this.props.data.bortleScale === 'C1 (Ciel excellent)' || this.props.data.bortleScale === 'C1 (Excellent dark-sky site)') {
      this.props.data.explanationOfBortleScale =
        "The zodiacal light, gegenschein, and zodiacal band are all visible — the zodiacal light to a striking degree, and the zodiacal band spanning the entire sky. Even with direct vision, the galaxy M33 is an obvious naked-eye object. The Scorpius and Sagittarius region of the Milky Way casts obvious diffuse shadows on the ground. To the unaided eye the limiting magnitude is 7.6 to 8.0 (with effort); the presence of Jupiter or Venus in the sky seems to degrade dark adaptation. Airglow (a very faint, naturally occurring glow most evident within about 15° of the horizon) is readily apparent. With a 32-centimeter (12½-inch) scope, stars to magnitude 17.5 can be detected with effort, while a 50-cm (20-inch) instrument used with moderate magnification will reach 19th magnitude. If you are observing on a grass-covered field bordered by trees, your telescope, companions, and vehicle are almost totally invisible. This is an observer's Nirvana!";
    } else if (this.props.data.bortleScale === 'C2 (Ciel vraiment noir)' || this.props.data.bortleScale === 'C2 (Typical truly dark site)') {
      this.props.data.explanationOfBortleScale =
        "Airglow may be weakly apparent along the horizon. M33 is rather easily seen with direct vision. The summer Milky Way is highly structured to the unaided eye, and its brightest parts look like veined marble when viewed with ordinary binoculars. The zodiacal light is still bright enough to cast weak shadows just before dawn and after dusk, and its color can be seen as distinctly yellowish when compared with the blue-white of the Milky Way. Any clouds in the sky are visible only as dark holes or voids in the starry background. You can see your telescope and surroundings only vaguely, except where they project against the sky. Many of the Messier globular clusters are distinct naked-eye objects. The limiting naked-eye magnitude is as faint as 7.1 to 7.5, while a 32-cm telescope reaches to magnitude 16 or 17.";
    } else if (this.props.data.bortleScale === 'C3 (Ciel rural)' || this.props.data.bortleScale === 'C3 (Rural sky)') {
      this.props.data.explanationOfBortleScale =
        "Some indication of light pollution is evident along the horizon. Clouds may appear faintly illuminated in the brightest parts of the sky near the horizon but are dark overhead. The Milky Way still appears complex, and globular clusters such as M4, M5, M15, and M22 are all distinct naked-eye objects. M33 is easy to see with averted vision. The zodiacal light is striking in spring and autumn (when it extends 60° above the horizon after dusk and before dawn) and its color is at least weakly indicated. Your telescope is vaguely apparent at a distance of 20 or 30 feet. The naked-eye limiting magnitude is 6.6 to 7.0, and a 32-cm reflector will reach to 16th magnitude.";
    } else if (this.props.data.bortleScale === 'C4 (Transition rural-urbain)' || this.props.data.bortleScale === 'C4 (Rural/suburban transition)') {
      this.props.data.explanationOfBortleScale =
        "Fairly obvious light-pollution domes are apparent over population centers in several directions. The zodiacal light is clearly evident but doesn't even extend halfway to the zenith at the beginning or end of twilight. The Milky Way well above the horizon is still impressive but lacks all but the most obvious structure. M33 is a difficult averted-vision object and is detectable only when at an altitude higher than 50°. Clouds in the direction of light-pollution sources are illuminated but only slightly so, and are still dark overhead. You can make out your telescope rather clearly at a distance. The maximum naked-eye limiting magnitude is 6.1 to 6.5, and a 32-cm reflector used with moderate magnification will reveal stars of magnitude 15.5.";
    } else if (this.props.data.bortleScale === 'C5 (Ciel péri-urbain)' || this.props.data.bortleScale === 'C5 (Suburban sky)') {
      this.props.data.explanationOfBortleScale =
        "Only hints of the zodiacal light are seen on the best spring and autumn nights. The Milky Way is very weak or invisible near the horizon and looks rather washed out overhead. Light sources are evident in most if not all directions. Over most or all of the sky, clouds are quite noticeably brighter than the sky itself. The naked-eye limit is around 5.6 to 6.0, and a 32-cm reflector will reach about magnitude 14.5 to 15.";
    } else if (this.props.data.bortleScale === 'C6 (Ciel de banlieue)' || this.props.data.bortleScale === 'C6 (Bright suburban sky)') {
      this.props.data.explanationOfBortleScale =
        "No trace of the zodiacal light can be seen, even on the best nights. Any indications of the Milky Way are apparent only toward the zenith. The sky within 35° of the horizon glows grayish white. Clouds anywhere in the sky appear fairly bright. You have no trouble seeing eyepieces and telescope accessories on an observing table. M33 is impossible to see without binoculars, and M31 is only modestly apparent to the unaided eye. The naked-eye limit is about 5.5, and a 32-cm telescope used at moderate powers will show stars at magnitude 14.0 to 14.5.";
    } else if (
      this.props.data.bortleScale === 'C7 (Transition banlieue-ville)' ||
      this.props.data.bortleScale === 'C7 (Suburban/urban transition)'
    ) {
      this.props.data.explanationOfBortleScale =
        "The entire sky background has a vague, grayish white hue. Strong light sources are evident in all directions. The Milky Way is totally invisible or nearly so. M44 or M31 may be glimpsed with the unaided eye but are very indistinct. Clouds are brilliantly lit. Even in moderate-size telescopes, the brightest Messier objects are pale ghosts of their true selves. The naked-eye limiting magnitude is 5.0 if you really try, and a 32-cm reflector will barely reach 14th magnitude.";
    } else if (this.props.data.bortleScale === 'C8 (Ciel de ville)' || this.props.data.bortleScale === 'C8 (City sky)') {
      this.props.data.explanationOfBortleScale =
        "The sky glows whitish gray or orangish, and you can read newspaper headlines without difficulty. M31 and M44 may be barely glimpsed by an experienced observer on good nights, and only the bright Messier objects are detectable with a modest-size telescope. Some of the stars making up the familiar constellation patterns are difficult to see or are absent entirely. The naked eye can pick out stars down to magnitude 4.5 at best, if you know just where to look, and the stellar limit for a 32-cm reflector is little better than magnitude 13.";
    } else if (this.props.data.bortleScale === 'C9 (Ciel de centre-ville)' || this.props.data.bortleScale === 'C9 (Inner-city sky)') {
      this.props.data.explanationOfBortleScale =
        "The entire sky is brightly lit, even at the zenith. Many stars making up familiar constellation figures are invisible, and dim constellations such as Cancer and Pisces are not seen at all. Aside from perhaps the Pleiades, no Messier objects are visible to the unaided eye. The only celestial objects that really provide pleasing telescopic views are the Moon, the planets, and a few of the brightest star clusters (if you can find them). The naked-eye limiting magnitude is 4.0 or less.";
    }
    let locationIcon;
    if (this.props.data.locationCategory === 'outdoor') {
      locationIcon = (
        <FontAwesomeIcon
          icon={faMapMarker}
          className="descriptionIconStyle"
          id="locationType"
        />
      );
    } else if (this.props.data.locationCategory === 'gite') {
      locationIcon = (
        <FontAwesomeIcon
          icon={faBed}
          className="descriptionIconStyle"
          id="locationType"
        />
      );
    } else if (this.props.data.locationCategory === 'observatoire') {
      locationIcon = (
        <img
          src={telescope}
          alt=""
          className="telescopeIconStyle"
          id="locationType"
        />
      );
    } else if (this.props.data.locationCategory === 'observatoire&gite') {
      locationIcon = (
        <div className="telBBStyle" id="locationType">
          <img alt="" src={telescope} className="telescopeIconStyle" />
          <FontAwesomeIcon icon={faBed} className="descriptionIconStyle" />
        </div>
      );
    }

    let token = localStorage.getItem('jwtToken');

    return (
      <div className="rootStyle">
        <Col xs="11" md="9">
          <Card className="cardStyle">
            <CardHeader className="heading">
              {locationIcon}
              <Tooltip
                placement="bottom"
                target="locationType"
                isOpen={this.state.locationTypeToolTip}
                toggle={this.locationTypeToolTipToggle}
              >
                Location Category : {this.props.data.locationCategory}
              </Tooltip>
              <h4 className="location-name">{this.props.data.locationName}</h4>
              <FontAwesomeIcon
                icon={faTimesCircle}
                onClick={() => this.closeComponent()}
                className="descriptionIconStyle"
              />
            </CardHeader>
            <CardBody className="descriptionCard">
              <div className="widgets">
                <FontAwesomeIcon
                  id="tooltipCar"
                  className="widgetCar"
                  icon={faCar}
                  onClick={this.openWidgetBbc}
                />
                <UncontrolledTooltip placement="bottom" target="tooltipCar">
                BlaBlaCar Carpooling
                </UncontrolledTooltip>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${this.props.data.latitude},${this.props.data.longitude}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gmapsLink"
                >
                  <FontAwesomeIcon
                    id="tooltipDirections"
                    className="widgetDirections"
                    icon={faDirections}
                  />
                </a>
                <UncontrolledTooltip placement="bottom" target="tooltipDirections">
                Google Maps Navigation
                </UncontrolledTooltip>
                <a
                  href={`https://www.waze.com/ul?ll=${this.props.data.latitude}%2C${this.props.data.longitude}&navigate=yes`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wazeLink"
                >
                  <FontAwesomeIcon
                    id="tooltipWaze"
                    className="widgetWaze"
                    icon={faWaze}
                  />
                </a>
                <UncontrolledTooltip placement="bottom" target="tooltipWaze">
                Waze Navigation
                </UncontrolledTooltip>
              </div>
              <button className="isochroneButton" onClick={() => this.launchIsochrone(this.props.data.latitude,this.props.data.longitude)}>Isochrone 30min</button>
              <div className="geocoord">
                <CardText className="latLong">
                  Latitude: {this.props.data.latitude}
                </CardText>
                <CardText className="latLong">
                  Longitude: {this.props.data.longitude}
                </CardText>
              </div>
              {this.props.data.webSite ? (
                <CardText className="webSiteLabel">
                Website:
                  <a
                    className="webSiteLink"
                    href={`${this.props.data.webSite}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.data.webSite}
                  </a>
                </CardText>
              ) : null}

              <div className="uniqueURL">{this.state.uniqueURL}</div>

              <CardText className="textdesc">
              Horizon Clearance : {this.props.data.isSouthernHorizonClear}
              </CardText>
              <div className="bortleStyle">
                <CardText className="paraStyle">
                  {this.props.data.explanationOfBortleScale}
                </CardText>
              </div>

              {this.renderAccessibilityIcons()}

              {this.state.ratingDataState.average ? (
                <div className="alignRating">
                  <p className="ratingElements"> Global Average Rating : </p>
                  <FontAwesomeIcon icon={faStar} style={{ color: '#FADB14' }} />
                  <p className="ratingElements">
                    {' '}
                    {this.state.ratingDataState.average}/5{' '}
                  </p>
                </div>
              ) : (
                <div className="alignRating">
                  <p className="ratingElements"> Global Average Rating : </p>
                  <FontAwesomeIcon icon={faStar} style={{ color: '#FADB14' }} />
                  <p className="ratingElements"> No Rating </p>
                </div>
              )}

              {this.state.ratingDataState.voteCount ? (
                <div className="alignRating">
                  <p className="ratingElements">
                    {' '}
                    Number of Ratings : {
                      this.state.ratingDataState.voteCount
                    }{' '}
                  </p>
                </div>
              ) : (
                <div className="alignRating">
                  <p className="ratingElements"> Number of Ratings : 0 </p>
                </div>
              )}

              {this.props.isAuthenticated === true ? (
                <div className="alignRating">
                  <p className="ratingElements">Your Rating : </p>
                  <Rater
                    className="ratingStars"
                    locationId={this.props.data._id}
                    transferRatingData={this.transferRatingData}
                  />
                </div>
              ) : null}

              <div className="buttonWeather">
                <Button outline color="secondary" className="buttonStyleSelec">
                Current weather
                </Button>{' '}
                <Button
                  outline
                  color="secondary"
                  className="buttonDescStyleUns"
                  onClick={this.showModal}
                >
                  Forecasted Weather
                </Button>{' '}
              </div>

              <div className="weatherInfo">
                <img
                  style={{
                    height: 100
                  }}
                  alt=""
                  src={
                    this.state.weatherDatas
                      ? 'https://openweathermap.org/img/w/' +
                        this.state.weatherDatas.weather[0].icon +
                        '.png'
                      : null
                  }
                  className="weatherIconStyle"
                />
                <div className="weatherTextStyle">
                  <p className="weatherdesc">
                  Weather conditions:{' '}
                    {this.state.weatherDatas
                      ? this.state.weatherDatas.weather[0].description
                      : null}
                  </p>
                  <p className="weatherdesc">
                  Cloud Cover:{' '}
                    {this.state.weatherDatas
                      ? this.state.weatherDatas.clouds.all
                      : null}
                    %
                  </p>
                  <p className="weatherdesc">
                    Temperature:{' '}
                    {this.state.weatherDatas
                      ? Math.round(this.state.weatherDatas.main.temp)
                      : null}
                    °C
                  </p>
                  <p className="weatherdesc">
                    Humidity:{' '}
                    {this.state.weatherDatas
                      ? this.state.weatherDatas.main.humidity
                      : null}
                    %
                  </p>
                </div>
              </div>

              <CardText className="textdesc">
                {this.props.data.observationCategory}
              </CardText>

              {/* COMMENTS */}

              <div className="comments-list">
                { this.renderComments() }
                { !token ? <p className="add-comment-signin">Add your comment by sign in</p> : "" }
              </div>

              { this.renderCommentForm() }

            </CardBody>
            <CardFooter className="footerStyle">

              <a target="_blank" rel="noopener noreferrer" href="https://www.meetstargazers.com/" id="toolTipInfo">
                <img width="36px" alt="Logo Meet Star Gazers" src={require('../images/meetstargazers_logo.png')}/>
              </a>

              <Tooltip
                placement="top"
                target="toolTipInfo"
                isOpen={this.state.toolTipOpenInfo}
                toggle={this.toggleInfo}
              >
                Our Mobile App : Meet Star Gazers
              </Tooltip>

              <FontAwesomeIcon
                onClick={() =>
                  this.addFavorite(this.props.userId, this.props.data._id)
                }
                icon={faHeart}
                style={colorHeart}
                className="descriptionIconStyle"
                id="tooltipfavoritedesc"
              />

              <Tooltip
                placement="top"
                target="tooltipfavoritedesc"
                isOpen={this.state.toolTipOpenFavorite}
                toggle={this.toggleFavoriteDesc}
              >
                Add or remove from favorites.
              </Tooltip>
            </CardFooter>
          </Card>
        </Col>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(
  mapStateToProps,
  null
)(Description);
