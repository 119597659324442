import React from "react";
import CloudsNavBar from "./CloudsNavBar";

function CloudsPrecipitation() {
  return (
    <div>
      <CloudsNavBar />
      <iframe
        src="https://www.meteoblue.com/en/weather/maps/widget?windAnimation=0&gust=0&satellite=0&cloudsAndPrecipitation=0&cloudsAndPrecipitation=1&temperature=0&sunshine=0&extremeForecastIndex=0&geoloc=detect&tempunit=C&windunit=km%252Fh&lengthunit=metric&zoom=5&autowidth=auto"
        frameBorder="0"
        scrolling="NO"
        allowtransparency="true"
        sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
        style={{
          width: "100%",
          height: "720px",
          margin: "0px 0px -6px",
        }}
      ></iframe>
      <div
        style={{
          backgroundColor: "#2A303B",
          height: "6vh",
          color: "#ffffff",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href="https://www.meteoblue.com/en/weather/maps/index?utm_source=weather_widget&utm_medium=linkus&utm_content=map&utm_campaign=Weather%2BWidget"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#ffffff" }}
        >
          Powered by <span style={{ fontWeight: "bold" }}>meteoblue</span>
        </a>
      </div>
    </div>
  );
}

export default CloudsPrecipitation;
