/* This reducer collects coordinates of searched city from SearchByCity component */
/* along with the trigger that will send this information to reducer and then to store and then to map component. */
/* searchCoordinates being an object we use spreadoperator mechanism to send received information. */
/* At start of web application, this reducer returns an empty object. */

export default function(searchCoordinates={}, action) {
  if(action.type === 'searchTrigger') {
    var searchCoordinatesCopy = {...searchCoordinates};
    searchCoordinatesCopy = action.coordinatesValue;
      return searchCoordinatesCopy;
  } else {
      return searchCoordinates;
  }
}
