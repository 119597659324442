import React from 'react';
import { GoogleMap, LoadScript, KmlLayer, Marker } from '@react-google-maps/api';
import NavBarLightPollutionAtlas from './NavBarLightPollutionAtlas.js';
import SearchByCity from './SearchByCity.js';
import { connect } from 'react-redux';
import AtlasLegend from './AtlasLegend.js';
import '../stylesheet/lightPollutionAtlas.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright, faAtlas } from '@fortawesome/free-solid-svg-icons';
import {Alert} from 'reactstrap';

class LightPollutionAtlas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 48.865127,
      lng: 2.350331,
      zoom: 8, // Min zoom 0 (whole world) max 20 (buildings)
      navigatorLat: 48.865127,
      navigatorLng: 2.350331,
      navGeoStatus: false,
      acknowledgeStatus: false,
      legendhidden: true
    };

    this.geoLocateMe = this.geoLocateMe.bind(this);
    this.showAcknowledgement = this.showAcknowledgement.bind(this);
    this.onDismissAck = this.onDismissAck.bind(this);
    this.displayLegend = this.displayLegend.bind(this);
    this.closeLegend = this.closeLegend.bind(this);

  }

/* Function to geolocate user and center the map at her/his position */
  geoLocateMe() {

    let ctx1 = this;
    navigator.geolocation.getCurrentPosition(
      function(position) {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        ctx1.setState({
          navigatorLat: pos.lat,
          navigatorLng: pos.lng,
          navGeoStatus: true,
          lat: pos.lat,
          lng: pos.lng
        });
      },
      function() {
        // this funtion is empty but the whole geolocation process won't work without it
      }
    );
  }

  /* Display acknowledgement of ISTIL reasearch along with timer to make acknowledgement disappear */
  showAcknowledgement() {
    this.setState({
      acknowledgeStatus: true
    })
    setTimeout(() => this.onDismissAck(), 5000);
  }

  /* Undisplay acknowledgement of ISTIL reasearch */
  onDismissAck() {
    this.setState({
      acknowledgeStatus: false
    });
  }

  /* Show legend for World Atlas of Night Sky Brightness */
  displayLegend() {
    this.setState({
      legendhidden: false
    });
  }

  /* Hide legend for World Atlas of Night Sky Brightness */
  closeLegend() {
    this.setState({
      legendhidden: true
    });
  }

  componentDidUpdate = (prevProps, prevStates) => {

    // Update the map's center on the screen and defines the new zoom level
    if (this.props.searchCoordinates !== prevProps.searchCoordinates) {
      if (
        Object.entries(this.props.searchCoordinates).length !== 0 &&
        this.props.searchCoordinates.constructor === Object
      ) {
        this.setState({
          lat: this.props.searchCoordinates.lat,
          lng: this.props.searchCoordinates.lng,
          zoom: 11
        });
      }
    }


    /* This condition detects if user has requested geolocation. The request is */
    /* collected from mapStateToProps which in turn collects it from the reducer. */
    if (this.props.triggerCount !== prevProps.triggerCount) {
      this.geoLocateMe();
    }
  }

  render() {
      return (
          <div className='atlasParentDiv'>
            <LoadScript
            googleMapsApiKey="AIzaSyAIQZSE4hWZYz9YcyNuTCSjjs6j3jObME0"
            >
              <GoogleMap
                center={{
                  lat: this.state.lat,
                  lng: this.state.lng }}
                mapContainerStyle={mapContainerStyle}
                options={mapOptions}
                zoom={this.state.zoom}
              >

             <KmlLayer
              url="https://artificialskybrightness.s3.eu-west-3.amazonaws.com/artificialNightSkyBrightness_compress.kml"
              />

              <NavBarLightPollutionAtlas geoLocateMe={this.geoLocateMe} />

              <SearchByCity />

              {this.state.navGeoStatus ? (
                <Marker
                  title="You are here"
                  icon={'https://maps.google.com/mapfiles/kml/shapes/placemark_circle.png'}
                  position={{
                    lat: this.state.navigatorLat,
                    lng: this.state.navigatorLng
                  }}
                />
              ) : null}

              <FontAwesomeIcon
                className="copyright"
                icon={faCopyright}
                onClick={()=>this.showAcknowledgement()}
              />

              {this.state.acknowledgeStatus &&
                <Alert color="primary" isOpen={this.state.acknowledgeStatus} toggle={this.onDismissAck} className="istilAcknowledgement">
                  Falchi, Fabio; Cinzano, Pierantonio; Duriscoe, Dan; Kyba, Christopher C. M.; Elvidge, Christopher D.; Baugh, Kimberly; Portnov, Boris; Rybnikova, Nataliya A.; Furgoni, Riccardo (2016): Supplement to: The New World Atlas of Artificial Night Sky Brightness. GFZ Data Services. http://doi.org/10.5880/GFZ.1.4.2016.001
                </Alert>
              }

              <FontAwesomeIcon
                className="legend"
                icon={faAtlas}
                onClick={()=>this.displayLegend()}
              />

              {!this.state.legendhidden &&
                <AtlasLegend closeLegend={this.closeLegend} />
              }

              </GoogleMap>

            </LoadScript>
          </div>
      )

  }
}

const mapContainerStyle = {
  width: '100vw',
  height: '100vh'
}

const mapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  minZoom: 6,
  gestureHandling: 'greedy'
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    searchCoordinates: state.searchCoordinates,
    triggerCount: state.triggerCount
  };
}

export default connect(mapStateToProps,null)(LightPollutionAtlas);
