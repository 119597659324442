import {combineReducers} from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import searchEngineReducer from './searchEngineReducer';
import triggerCount from './geoLocationReducer.js';
import alert from './alert.js';

/* This component combines and saves all reducers that will be sent to store for saving. */

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  searchCoordinates: searchEngineReducer,
  triggerCount: triggerCount,
  alert: alert
});
