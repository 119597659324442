/* This reducer captures trigger sent by this.props.geoLocateMe in */
/* NavigationBar.js component. If trigger is captured, reducer in turn sends */
/* an integer count value incremented by 1. If not, count value is initialised */
/* as zero & sent by this reducer. */

export default function(triggerCount=0, action) {
  if(action.type === 'geoLocateMeTrigger') {
      return triggerCount + 1;
  } else {
      return triggerCount;
  }
}
