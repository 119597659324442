import React from 'react';

// styling
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import '../stylesheet/searchByCity.css';

// api
import config from "../config/environment.js";
import axios from 'axios';

// redux
import {connect} from 'react-redux';

class SearchByCity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cityQuery: '',
      cityLatLngQueryResult: {},
    };

    this.captureCity = this.captureCity.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);

  }

  captureCity(event){

    /* Prevents default action of page refresh which we can't let happen in React (SPA) */
    event.preventDefault();

    /* This function captures data entered in search city field */
    this.setState({
      cityQuery: event.target.value
    });
  }

  handleSearchRequest(event, signal) {
    let ctx1 = this;
    // Prevents default action of page refresh which we can't let happen in React (SPA)
    event.preventDefault();

    var code = event.keyCode || event.which;

    // If enter key is pressed or launch search button is pressed
    if (code === 13 || signal === 'searchAction') {

      axios.post(`${config.config.urlFetch}/api/locations/getSearchCityLatLng`, { input: ctx1.state.cityQuery.trim() })
      .then(function (response) {
        if (response.data.searchedLocation.candidates.length > 0) {
          let queryCoordinates = {...response.data.searchedLocation.candidates[0].geometry.location};
          ctx1.setState({
            cityLatLngQueryResult: queryCoordinates
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }

  componentDidUpdate(prevState) {
     if (this.state.cityLatLngQueryResult !== prevState.cityLatLngQueryResult) {
        if ((Object.entries(this.state.cityLatLngQueryResult).length !== 0) && (this.state.cityLatLngQueryResult.constructor === Object)) {
          /* if new city query is different than the preceeding one */
          /* if querylatlng object is non-empty i.e contains lat long && */
          /* object is a datatype object then send latitude longitude information as argument */
          /* to redux mapdispatch to props function. */
          this.props.collectQueryLatLng(this.state.cityLatLngQueryResult);
        }
     }
  }

  render() {

    return (
      <div className="searchCityDiv">
        <InputGroup>
          <Input className="searchCityTextArea" placeholder="Search in English only" onChange={this.captureCity} onKeyUp={(event) => ((event.keyCode === 13) || (event.which === 13)) && this.handleSearchRequest(event, 'searchAction')}/>
          <InputGroupAddon addonType="append"><Button onClick={(event) => this.handleSearchRequest(event, 'searchAction')} className="searchCityButton"><FontAwesomeIcon icon={faSearch} /></Button></InputGroupAddon>
        </InputGroup>
      </div>
    )
  }
}

/* This function sends coordinates object to reducer along with trigger for the same. */
function mapDispatchToProps(dispatch) {
  return {
    collectQueryLatLng: function(coordinatesValue) {
        dispatch( {
          type: 'searchTrigger',
          coordinatesValue: coordinatesValue,
        } )
    }
  }
}


export default connect(
    null,
    mapDispatchToProps
)(SearchByCity);
