import React from 'react';
import {Button, Form, FormGroup, Input, Badge} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../stylesheet/signup.css";
import {Redirect, Link, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {registerUser} from '../actions/authActions'
import PropTypes from 'prop-types';

/* npm package that can be used to verify field format */
var passwordValidator = require('password-validator');

/* Component that performs signup of new user */
class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectMap: false,
      email: '',
      password: '',
      password2: '',
      passwordErrorTable: [],
      emailErrorTable: [],
      passwordErrorStatus: false,
      password2ErrorStatus: false,
      emailErrorStatus: false,
      errors: {}
    };

    this.captureEmailData = this.captureEmailData.bind(this);
    this.capturePasswordData = this.capturePasswordData.bind(this);
    this.capturePassword2Data = this.capturePassword2Data.bind(this);
    this.handleClickSignUp = this.handleClickSignUp.bind(this);
  }

  /* captureEmailData captures data entered in email field */
  captureEmailData(event) {
    event.preventDefault();
    this.setState({email: event.target.value});

  }

  /* capturePasswordData captures data entered in password field */
  capturePasswordData(event) {
    /* Prevents default action of page refresh which we can't let happen in React (SPA) */
    event.preventDefault();

    this.setState({password: event.target.value});

  }

  /* capturePassword2Data captures data entered in password2 field */
  capturePassword2Data(event) {
    /* Prevents default action of page refresh which we can't let happen in React (SPA) */
    event.preventDefault();

    this.setState({password2: event.target.value});

  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({errors: nextProps.errors})
    }
  }

  /* This fn sends username, email & password info to backend to save into database */
  /* The userid generated by database for this entry is then returned to be used */
  /* as input for redux to be shared through reducer. Fn onLoginClick sends information */
  /* to container component. */
  /* This fn feeds data to backend with fetch function and program redirects user to map */
  handleClickSignUp(event, signal) {

    /* Prevents default action of page refresh which we can't let happen in React (SPA) */
    event.preventDefault();

    /* creation of schema for password & email robustness validation */
    var schemaPassword = new passwordValidator();
    var schemaEmail = new passwordValidator();

    /* Add properties to password validation schema */
    // Minimum length 8
    // Maximum length 100
    // Must have digits
    // Should not have spaces
    // Blacklist these values
    schemaPassword.is().min(8).is().max(100).has().digits().has().not().spaces().is().not().oneOf(['Passw0rd', 'Password123', 'azerty', 'qwerty']);

    /* Add properties to email validation schema */
    // Minimum length 7
    // Maximum length 100
    // Should not have spaces
    schemaEmail.is().min(7).is().max(100).has().not().spaces()

    var ctx = this;

    var code = event.keyCode || event.which;


      /* If email fulfill defined conditions in schema & email contains @ character then test next if condition */
      if ((schemaEmail.validate(ctx.state.email)) && ((this.state.email.indexOf("@")) > -1)) {

        /* If password fulfill defined conditions in schema then test next if condition */
        if (schemaPassword.validate(ctx.state.password)) {

          /* if password & password2 fields match then test next if condition */
          if (ctx.state.password === ctx.state.password2) {

            /* if enter key or signup button(signal==='submitAction') is pressed to submit data then send POST to back-end */
            if (code === 13 || signal === 'submitAction') {

              const newUser = {
                userName: this.state.email,
                email: this.state.email,
                password: this.state.password,
                password2: this.state.password2
              }

              this.props.registerUser(newUser, this.props.history);

            }/* no else statement for if (code===13 || code===undefined) */

          } else {
            /* password and password2 fields don't match */
            ctx.setState({password2ErrorStatus: true})
          }
        } else {
          // Get a full list of rules which failed for password
          if (!(schemaPassword.validate(ctx.state.password))) {
            ctx.setState({
              passwordErrorTable: schemaPassword.validate(ctx.state.password, {list: true}),
              passwordErrorStatus: true
            })
          }
        }
      } else {
        // Get a full list of rules which failed for email
        if ((!(schemaEmail.validate(ctx.state.email))) || ((this.state.email.indexOf("@")) < 0)) {
          ctx.setState({
            emailErrorTable: schemaEmail.validate(ctx.state.email, {list: true}),
            emailErrorStatus: true
          })
        }
      }

  }

  render() {

    var passwordErrorPrompt = null;

    if (this.state.passwordErrorStatus) {
      var passwordErrorArray = [];

      for (var i = 0; i < this.state.passwordErrorTable.length; i++) {
        if (this.state.passwordErrorTable[i] === 'min') {
          passwordErrorArray.push("Min length 8 characters");
        } else if (this.state.passwordErrorTable[i] === 'max') {
          passwordErrorArray.push("Max length 100 characters");
        } else if (this.state.passwordErrorTable[i] === 'digits') {
          passwordErrorArray.push("Must contain at least one digit");
        } else if (this.state.passwordErrorTable[i] === 'spaces') {
          passwordErrorArray.push("Must not contain spaces");
        } else if (this.state.passwordErrorTable[i] === 'oneOf') {
          passwordErrorArray.push("Forbidden word used");
        }
      }
      passwordErrorPrompt = passwordErrorArray.toString();
    }

    var emailErrorPrompt = null;

    if (this.state.emailErrorStatus) {
      var emailErrorArray = [];

      for (var j = 0; j < this.state.emailErrorTable.length; j++) {
        if (this.state.emailErrorTable[j] === 'min') {
          emailErrorArray.push("Min length 7 characters");
        } else if (this.state.emailErrorTable[j] === 'max') {
          emailErrorArray.push("Max length 100 characters");
        } else if (this.state.emailErrorTable[j] === 'spaces') {
          emailErrorArray.push("Must not contain spaces");
        }
      }
      if ((this.state.email.indexOf("@")) < 0) {
        emailErrorArray.push("Must contain @");
      }

      emailErrorPrompt = emailErrorArray.toString();
    }


    return (<div className="background">

      {
        this.state.redirectMap
          ? <Redirect to="/map"/>
          : null
      }

      <Form className="formSignup">

        <h1 className="signUpText">Create your account</h1>

        <div className="signUpInstructionEnveloppe">
          <p className="signupInstruction">Please use only letters of the alphabet and numbers.</p>
        </div>

        <div className="signupFields">


          <FormGroup className="emailSignupForm">
            <Input type="email" name="email" className={this.state.emailErrorStatus
                ? "emailSignupInputError"
                : "emailSignupInput"} placeholder="Email" onChange={this.captureEmailData} onKeyUp={(event) => ((event.keyCode === 13) || (event.which === 13)) && this.handleClickSignUp}/>
          </FormGroup>

          {
            this.state.emailErrorStatus
              ? <p className="emailErrorPromptStyle">{emailErrorPrompt}</p>
              : null
          }

          <FormGroup className="passwordSignupForm">
            <Input type="password" name="password" className={this.state.passwordErrorStatus
                ? "passwordSignupInputError"
                : "passwordSignupInput"} placeholder="Password" onChange={this.capturePasswordData} onKeyUp={(event) => ((event.keyCode === 13) || (event.which === 13)) && this.handleClickSignUp}/>
          </FormGroup>

          {
            this.state.passwordErrorStatus
              ? <p className="passwordErrorPromptStyle">{passwordErrorPrompt}</p>
              : null
          }

          <FormGroup className="passwordSignupForm">
            <Input type="password" name="password2" className={this.state.password2ErrorStatus
                ? "passwordSignupInputError"
                : "passwordSignupInput"} placeholder="Please confirm your password" onChange={this.capturePassword2Data} onKeyUp={(event) => ((event.keyCode === 13) || (event.which === 13)) && this.handleClickSignUp}/>
          </FormGroup>

          {
            this.state.password2ErrorStatus
              ? <p className="passwordErrorPromptStyle">Passwords entered in both fields must be identical</p>
              : null
          }


          <FormGroup className="signupButtonForm">
            <Link to="/map">
              <Button type="button" className="cancelButton">Cancel</Button>
            </Link>
            <Button type="submit" className="submit" onClick={(event) => this.handleClickSignUp(event, 'submitAction')}>Sign up</Button>
          </FormGroup>

          <Link to="/signin">
            <Badge className="connectionBadge" color="light">Sign in</Badge>
          </Link>

        </div>

      </Form>

    </div>);
  }
}

// function mapDispatchToProps(dispatch) {
//   return {
//     onLoginClick: function(userId) {
//         dispatch( {type: 'display', userId:userId} )
//     }
//   }
// }
//
// export default connect(
//     null,
//     mapDispatchToProps
// )(Signup);

Signup.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Signup));
