import React from 'react';
import '../stylesheet/maplegend.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';

/* Legend for Main Map */
class MapLegend extends React.Component {
  constructor(props) {
    super(props);
    this.closeLegend = this.closeLegend.bind(this);
  }

  closeLegend() {
    this.props.closeLegend();
  }

  render(){

    return(
      <div className='atlasLegendParent'>
      <FontAwesomeIcon className='legendClose' icon={faTimesCircle} onClick={()=>this.closeLegend()}/>
      <div className='topRow'>
        <p className='legendHeading'>Key : Bortle Scale</p>
      </div>
      <div className='legendContent'>
        <div className='colourColumn'>
          <div className='placeBlack'></div>
          <div className='placeDarkBlue'></div>
          <div className='placeLightBlue'></div>
          <div className='placeGreen'></div>
          <div className='placeYellow'></div>
          <div className='placeOrange'></div>
          <div className='placeRed'></div>
          <div className='placePink'></div>
          <div className='placeWhite'></div>
          <div className='placeDogwoodRose'></div>
        </div>
        <div className='bortleColumn'>
          <p className='bortleText'>C1 Excellent dark-sky site</p>
          <p className='bortleText'>C2 Typical truly dark site</p>
          <p className='bortleText'>C3 Rural sky</p>
          <p className='bortleText'>C4 Rural/suburban transition</p>
          <p className='bortleText'>C5 Suburban sky</p>
          <p className='bortleText'>C6 Bright suburban sky</p>
          <p className='bortleText'>C7 Suburban/urban transition</p>
          <p className='bortleText'>C8 City sky</p>
          <p className='bortleText'>C9 Inner-city sky</p>
          <p className='bortleText'>User Position</p>
        </div>
      </div>
      </div>
    )
  }

}

export default (MapLegend);
