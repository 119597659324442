import React from 'react';
import {Button, Form, FormGroup, Input, Badge} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../stylesheet/signin.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loginUser } from '../actions/authActions';
import Alert from './Alert.js';

/* captureEmailData captures data entered in email field */
/* capturePasswordData captures data entered in password field */
/* handleClickSignIn sends data to backend with fetch function and verifies if user exists in database and if yes program directs user to map */

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    }
    this.captureEmailData = this.captureEmailData.bind(this);
    this.capturePasswordData = this.capturePasswordData.bind(this);
    this.handleClickSignIn = this.handleClickSignIn.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/map');
    }
  }

  captureEmailData(event) {
    event.preventDefault();
    this.setState({ email: event.target.value });
  }

  capturePasswordData(event) {
    event.preventDefault();
    this.setState({ password: event.target.value });
  }

  handleClickSignIn = (event, signinSignal) => {
    event.preventDefault();

    var code = event.keyCode || event.which;

      /* code===13 OR code===undefined ensures that enter key or connexion button is pressed before making fetch call to backend */
      if (code === 13 || signinSignal === 'signinAction') {
        const userData = {
          email: this.state.email,
          password: this.state.password
        };

        this.props.loginUser(userData);
      }

  }

  render() {

    return (
      <div className="parentDiv">
          <Alert />

          <div className="background">

            <Form className="formSignin">

              <h1 className="signInTitle">Log in :</h1>

              <div className="signInInstructionEnveloppe">
                <p className="signInInstruction">Please check that you have entered the email and not the username.</p>
              </div>

              <FormGroup className="emailFormGroup">
                <Input type="email" name="email" onChange={this.captureEmailData} onKeyUp={this.handleClickSignIn} className="email" placeholder="Email" />
              </FormGroup>

              <FormGroup className="passwordFormGroup">
                <Input type="password" name="password" onChange={this.capturePasswordData} onKeyUp={this.handleClickSignIn} className="password" placeholder="Password" />
              </FormGroup>

              <FormGroup className="signinButtonForm">
                <Link to="/map">
                  <Button type="button" className="cancelButton1">Cancel</Button>
                </Link>
                <Button type="submit" onClick={(event) => this.handleClickSignIn(event, 'signinAction')} className="submit1" color="secondary">Sign in</Button>
              </FormGroup>

            <Link to="/ForgotPassword">
              <p className="forgotPassword" color="light">
               Forgot your password ?
              </p>
            </Link>
            <Link to="/signup">
              <Badge className="inscriptionBadge" color="light">
              Sign up
              </Badge>
            </Link>
          </Form>
        </div>
      </div>
    );
  }
};


Signin.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({auth: state.auth});

export default connect(
  mapStateToProps,
  { loginUser }
)(withRouter(Signin));
