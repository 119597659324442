import React, { Component } from "react";
import Signin from "./component/signin.js";
import ForgotPassword from "./component/ForgotPassword.js";
import ResetPassword from "./component/ResetPassword.js";
import Signup from "./component/signup.js";
import Map from "./component/Map.js";
import LandingPage from "./component/landingPage.js";
import CloudsPrecipitation from "./component/CloudsPrecipitation.js";
import MoonEngine from "./component/MoonEngine.js";
import LightPollutionAtlas from "./component/LightPollutionAtlas.js";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { logoutUser } from "./actions/authActions";
import { setCurrentUser } from "./actions/authActions";

/* import of provider */
import { Provider } from "react-redux";

import store from "./store";

// Check for Token
if (localStorage.jwtToken) {
  //Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  //Decode token and get user infos and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  //Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
  }
}

/*react-router-dom implements navigation between different components of webapp*/
/*react-router-dom implements paths to make navigation between components to work*/

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/map" component={Map} />
              <Route exact path="/map/:location" component={Map} />
              <Route
                exact
                path="/nightSkyBrightness"
                component={LightPollutionAtlas}
              />
              <Route
                path="/humanfootprint"
                render={() => <Redirect to="/nightSkyBrightness" />}
              />
              <Route path="/signin" component={Signin} />
              <Route path="/ForgotPassword" component={ForgotPassword} />
              <Route
                exact
                path="/ResetPassword/:id"
                component={ResetPassword}
              />
              <Route path="/signup" component={Signup} />
              <Route
                path="/cloudsprecipitation"
                component={CloudsPrecipitation}
              />
              <Route
                path="/weatherengine"
                render={() => <Redirect to="/cloudsprecipitation" />}
              />
              <Route exact path="/moonengine" component={MoonEngine} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
