import axios from 'axios';
import config from "../config/environment.js";
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { setAlert } from './alert';

import {
  GET_ERRORS,
  SET_CURRENT_USER
} from './types';

//Register user
export const registerUser = (userData, history) => dispatch => {
  axios
    .post(`${config.config.urlFetch}/api/users/signup`, userData)
    .then(function (response) {
      history.push('/signin');
    })
    .catch(function (error) {
      console.log('error', error);
    });
};

//Login - Get user token

export const loginUser = userData => dispatch => {
  fetch(`${config.config.urlFetch}/api/users/signin`, {
   method: 'POST',
   headers: {'Content-Type':'application/x-www-form-urlencoded'},
   body: `email=${userData.email}&password=${userData.password}`
  }).then(function(response) {
    return response.json();
  })
  .then(function(data) {
    //Extract token
    const {token, success} = data;

    if (token === undefined) {

      //If signin unsuccessful
      if (data.email) {
        dispatch(setAlert(data.email, 'danger'));
      }

      if (data.password) {
        dispatch(setAlert(data.password, 'danger'));
      }

    } else if (token !== undefined && success === true) {

      //If signin successful
      //Set token to local storage
      localStorage.setItem('jwtToken', token);
      //Set token to auth header
      setAuthToken(token);
      //decode token to get user data
      const decoded = jwt_decode(token);
      //Set current user
      dispatch(setCurrentUser(decoded));
    }

  })
  .catch(function(error) {
    console.log('Request failed', error)
  });
}

//Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }
}

//Log user out
export const logoutUser = () => dispatch => {
  //Remove token from localStorage
  localStorage.removeItem('jwtToken');
  //Remove auth header for future Request
  setAuthToken(false);
  //Set current user to {} wich will set isAuthenticated to false
  dispatch(setCurrentUser({}));
}
