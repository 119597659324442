
/**
 * Call the URL based on the environment (Dev or Prod)
 */

const PROD_URL = "https://immense-sea-10962.herokuapp.com";
// const DEV_URL = "https://mysterious-springs-62748.herokuapp.com";
// const LOCAL_URL = "http://192.168.1.86:5000";

var config = {
  config: {
    urlFetch: PROD_URL
  }
};

export default config;
