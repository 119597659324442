import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Map, Marker, GoogleApiWrapper, Polygon } from 'google-maps-react';
import Description from './Description';
import Favoris from './Favoris';
import Moon from './Moon';
import MapLegend from './MapLegend';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../stylesheet/Map.css';
import '../stylesheet/favoris.css';
import { Link } from 'react-router-dom';
import '../stylesheet/description.css';
import '../stylesheet/weatherforecast.css';
import '../stylesheet/maplegend.css';
import NavigationBar from './NavigationBar.js';
import { connect } from 'react-redux';
import config from '../config/environment.js';
import CollectionCreateForm from './addLocationFromClick.js';
import WeatherForecast from './weatherforecast';
import WidgetBlablaCar from './widgetBlaBlaCar';
import black from '../images/marker_000000.png';
import darkBlue from '../images/marker_000467.png';
import lightBlue from '../images/marker_4040FF.png';
import green from '../images/marker_989623.png';
import yellow from '../images/marker_FEFE40.png';
import orange from '../images/marker_F9AF0B.png';
import red from '../images/marker_FF1E00.png';
import pink from '../images/marker_E991CF.png';
import white from '../images/marker_FFFFFF.png';
import userMarker from '../images/marker_D90368.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAtlas} from '@fortawesome/free-solid-svg-icons';

/* code in componentWillMount capture users current position & centers map on captured position */
/* map function iterating on locations array prepares data for generation of markers */
/* {markerlist} array generates markers on map */
/* export default GoogleApiWrapper component contains map, takes API key as input and needs map container to function */
/* toggle function is used by hamburger menu */
/* toggleDescription function displays description when user clicks on a marker */
/* returnToDescription function displays description and makes details disappear when user clicks on retour */
/* closeWindow function closes description and details windows respectively when user clicks on x sign at top right corner */

export class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 48.865127,
      lng: 2.350331,
      navigatorLat: 48.865127,
      navigatorLng: 2.350331,
      navGeoStatus: false,
      isOpen: false,
      connectStatus: false,
      locations: [],
      showDescription: false,
      showFavorite: false,
      showForecast: false,
      showPopUpBbc: false,
      connection: true,
      modal: false,
      weatherDatas: {},
      favoriteLiked: null,
      refreshNewMarker: true,
      legendhidden: true,
      clickLatLng: null,
      visible: false,
      values: null,
      moonPhase: '',
      ageMoon: null,
      angularDiameter: null,
      distanceMoonEarth: null,
      moonIllumination: null,
      isShown: false,
      isochroneData: null
    };

    this.toggle = this.toggle.bind(this);
    this.toggleDescription = this.toggleDescription.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
    this.returnToDescription = this.returnToDescription.bind(this);
    this.addFavorite = this.addFavorite.bind(this);
    this.displayFavorite = this.displayFavorite.bind(this);
    this.getMarker = this.getMarker.bind(this);
    this.displayLegend = this.displayLegend.bind(this);
    this.closeLegend = this.closeLegend.bind(this);
    this.geoLocateMe = this.geoLocateMe.bind(this);
    this.launchIsochrone = this.launchIsochrone.bind(this);

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  /* This function is called to display description of a location */
  /* a setState is performed directly on data without any initialisation */
  toggleDescription(data) {
    var ctx = this;
    fetch(`${config.config.urlFetch}/api/locations/getLocationWeatherInfos`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'latitude=' + data.latitude + '&longitude=' + data.longitude
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(weatherData) {
        var weatherDataCopy = {
          ...weatherData
        };
        ctx.setState({ weatherDatas: weatherDataCopy });
      });

    this.setState({
      showDescription: true,
      data: {
        ...data
      },
      lat: data.latitude,
      lng: data.longitude
    });
  }

  /*this function is called to display the weather forecast component */
  /* dataObject contains all information about a given location */
  toggleForecast = dataObject => {
    if (this.props.isAuthenticated === true) {
      this.setState({
        showDescription: false,
        showForecast: true,
        connection: true,
        dataObject: {
          ...dataObject
        }
      });
    } else {
      this.setState({
        connection: false,
        modal: !this.state.modal
      });
    }
  };

  /*this function is called to display the blablacar component */
  /* dataObject contains all information about a given location */
  toggleWidgetBbc = dataObject => {
    if (this.props.isAuthenticated === true) {
      this.setState({
        showDescription: false,
        showPopUpBbc: true,
        connection: true,
        dataObject: {
          ...dataObject
        }
      });
    } else {
      this.setState({
        connection: false,
        modal: !this.state.modal
      });
    }
  };

  /* This function is called when user returns from details to description of a location */
  /* A new query is made to openweathermap API to obtain updated weather information */
  returnToDescription(data) {
    var ctx = this;
    fetch(`${config.config.urlFetch}/api/locations/getLocationWeatherInfos`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'latitude=' + data.latitude + '&longitude=' + data.longitude
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(weatherData) {
        var weatherDataCopy = {
          ...weatherData
        };
        ctx.setState({ weatherDatas: weatherDataCopy });
      });
    this.setState({ showDescription: true });
  }

  /* This function is called in weatherforecast component.*/
  /* It closes forecast component and opens description component*/
  /* A new query is made to openweathermap API to obtain updated weather information */
  returnToDescriptionFromForecast = data => {
    var ctx = this;
    fetch(`${config.config.urlFetch}/api/locations/getLocationWeatherInfos`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'latitude=' + data.latitude + '&longitude=' + data.longitude
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(weatherData) {
        var weatherDataCopy = {
          ...weatherData
        };
        ctx.setState({ weatherDatas: weatherDataCopy });
      });
    this.setState({ showDescription: true, showForecast: false });
  };

  /* This function is called in widgetBlaBlaCar component.*/
  /* It closes widgetBlaBlaCar component and opens description component*/
  /* A new query is made to openweathermap API to obtain updated weather information */
  returnToDescriptionFromWidgetBbc = data => {
    var ctx = this;
    fetch(`${config.config.urlFetch}/api/locations/getLocationWeatherInfos`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'latitude=' + data.latitude + '&longitude=' + data.longitude
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(weatherData) {
        var weatherDataCopy = {
          ...weatherData
        };
        ctx.setState({ weatherDatas: weatherDataCopy });
      });
    this.setState({ showDescription: true, showPopUpBbc: false });
  };


  closeWindow() {
    this.setState({
      showDescription: false,
      showFavorite: false,
      showForecast: false
    });
  }

  displayFavorite() {
    this.setState({
      showFavorite: true,
      showDescription: false,
    });
  }

  displayLegend() {
    this.setState({ legendhidden: false });
  }

  closeLegend() {
    this.setState({ legendhidden: true });
  }

  componentWillMount() {
    /* Get all locations to create markers route */
    const ctx2 = this;
    fetch(`${config.config.urlFetch}/api/locations/map`)
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        ctx2.setState({ locations: data.locations });
      });

    /* This block of code checks if unique location url argument has been detected in url */
    /* If the location ID argument is present in url, it is captured and sent as post request to  */
    /* /oneLocation route in backend. The answer received from backend is all the information related to a given spot */
    /* latitude and logtiude information are extracted and fed to lat and lng states to update them */
    /* This centers the map on these coordinates. center parameter was added to Map component to accomodate this logic. */
    if (this.props.match.params.location !== undefined) {
      var ctx3 = this;
      fetch(`${config.config.urlFetch}/api/locations/oneLocation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'uniqueLocationId=' + this.props.match.params.location
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(uniqueLocationInfo) {
          if (
            uniqueLocationInfo.uniqueLocationInfo.latitude &&
            uniqueLocationInfo.uniqueLocationInfo.longitude
          ) {
            ctx3.setState({
              lat: uniqueLocationInfo.uniqueLocationInfo.latitude,
              lng: uniqueLocationInfo.uniqueLocationInfo.longitude
            });
          }
        })
        .catch(function(error) {
          console.log('Request failed', error);
        });
    }
  }

  /* code in this.getMarker called in componentDidMount phase collects locations from database to prepare generation of markers */
  async componentDidMount() {
    this.getMarker();


    /* Moon data Farmsense API request */
    /* We collect moonPhase, ageMoon, angularDiameter, distanceMoonEarth & */
    /* moonIllumination values which are states in Map component */
    /* and they are collected as props in Moon component. */
    var unixTimeStamp = Math.round(new Date().getTime() / 1000);

    const ctx = this;
    await fetch(`${config.config.urlFetch}/api/locations/getMoonData`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: 'unixTimeStamp=' + unixTimeStamp
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(moonDatas) {
        var moonDatasCopy = { ...moonDatas[0] };

        ctx.setState({
          moonPhase: moonDatasCopy.Phase,
          ageMoon: moonDatasCopy.Age.toFixed(2),
          angularDiameter: moonDatasCopy.AngularDiameter.toFixed(2),
          distanceMoonEarth: moonDatasCopy.Distance.toFixed(2),
          moonIllumination: moonDatasCopy.Illumination.toFixed(2) * 100
        });
      });
  }

  /* This function compares toggles this.state.lpMapIsOpen to control display */
  /* of light pollution overlay. this.props.isLpOpen comes from Redux mapStateToProps */
  /* this.state.lpMapIsOpen is initialised in Map.js component and updated in this function */
  /* state of this.state.lpMapIsOpen is used to control activeOverlay */
  componentDidUpdate = (prevProps, prevStates) => {

    /* If there has been no search performed by user, this.props.searchCoordinates is an empty object */
    /* returned by default by reducer/store. First if condition verifies that currect searchCoordinates object is different that preceeding one. */
    /* Second if condition verifies that this.props.searchCoordinates is non-empty i.e. contains */
    /* lat lng information && belongs to datatype object. If both if conditions are satisfied */
    /* the coordinates of searched city and attributed to lat lng states that become new */
    /* centre of map. */
    if (this.props.searchCoordinates !== prevProps.searchCoordinates) {
      if (
        Object.entries(this.props.searchCoordinates).length !== 0 &&
        this.props.searchCoordinates.constructor === Object
      ) {
        this.setState({
          lat: this.props.searchCoordinates.lat,
          lng: this.props.searchCoordinates.lng
        });
      }
    }

    /* This condition detects if user has requested geolocation. The request is */
    /* collected from mapStateToProps which in turn collects it from the reducer. */
    if (this.props.triggerCount !== prevProps.triggerCount) {
      this.geoLocateMe();
    }
  }

  getMarker() {
    const ctx = this;
    fetch(`${config.config.urlFetch}/api/locations/map`)
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        ctx.setState({ locations: data.locations });
      });
  }


  //-------Import de NavigationBar avant Reducer dans Map------//
  //-------Import de NavigationBarDisplay après Reducer dans Map-----//
  /* isLiked boolean received from description and details components */
  /* it is used in addFavorite function in map to toggle liked unliked status */
  /* of a given location through if else logic */

  addFavorite(userId, locationId, isLiked) {
    if (this.props.isAuthenticated === true) {
      let ctx = this;

      if (isLiked) {
        fetch(`${config.config.urlFetch}/api/users/deletefavorite`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: 'userId=' + ctx.props.user.id + '&locationId=' + locationId
        })
          .then(function(response) {
            return response.json();
          })
          .then(function(data) {});
      } else {
        fetch(`${config.config.urlFetch}/api/users/addfavorite`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: 'userId=' + ctx.props.user.id + '&locationId=' + locationId
        })
          .then(function(response) {
            return response.json();
          })
          .then(function(data) {});
      }
    } else {
      this.setState({
        connection: false,
        modal: !this.state.modal
      });
    }
  }

  //Add Location from click: methods zone --------------------------------------------

  /* This function switches visibility of modal to true */
  /* this.state.visible is visibility of add location modal */
  showModal = () => {
    this.setState({ visible: true });
  };

  /* This function switches visibility of modal to false i.e. makes it disappear */
  /* this.state.visible is visibility of add location modal */
  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (values) {
        var ctx = this;
        fetch(`${config.config.urlFetch}/api/locations/addlocation`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        })
          .then(function(response) {
            return response.json();
          })
          .then(function(data) {
            ctx.getMarker();
          });
      }

      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  //This is the function that get coordinates from the click on map and open the form to create a new location
  onMapClick = (mapProps, map, clickEvent) => {
    this.setState({
      clickLatLng: {
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng()
      },
      lat: clickEvent.latLng.lat(),
      lng: clickEvent.latLng.lng()
    });
    this.showModal();
  };

  //Add Location from click methods zone end --------------------------------------------


  /* this.state.navGeoStatus is used to determine if the browser has succeeded in geolocating the user */
  /* If yes then a red marker is placed at the given point. If no then no red marker is placed */
  /* When this.state.showForecast is true, WeatherForecast component is displayed. */

  /* This function is responsible for geolocating the user following his/her request */
  /* by clicking on location arrow icon in navbar. */
  geoLocateMe() {
    // This bloc of code gets the user's geolocation from his browser
    /* It uses navigatorLat and navigatorLng to capture the user's position */
    /* which in turn is captured by the browser. navigatorLat & navigatorLng are independant  */
    /* from lat and lng to avoid moving the red marker at every click on another marker */
    /* This block of code positions red marker on user's position as well as */
    /* centres map on user's position. */

    var ctx = this;
    navigator.geolocation.getCurrentPosition(
      function(position) {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        ctx.setState({
          navigatorLat: pos.lat,
          navigatorLng: pos.lng,
          navGeoStatus: true,
          lat: pos.lat,
          lng: pos.lng
        });
      },
      function() {
        // this funtion is empty but the whole geolocation process won't work without it
      }
    );
  }

  // Calculates isochrone for given location
  launchIsochrone(latitude,longitude) {

    if (latitude && longitude) {
      let ctx5 = this;
      /* If lat & lng values are available, we trigger call to Bing Isochrone API */
      fetch(`https://dev.virtualearth.net/REST/v1/Routes/Isochrones?waypoint=${latitude},${longitude}&maxTime=30&timeUnit=minute&travelMode=driving&key=AhiSC176Yfwvb13AWLKnEJ4av38UjArLCc4QMXC1YsbRRf3rHF2X8dcbT5cO09dj`).then(function(response) {
        return response.json();
      }).then(function(data) {

        if (data.resourceSets[0].resources[0].polygons[0].coordinates[0].length > 0) {
          let coords = data.resourceSets[0].resources[0].polygons[0].coordinates[0].map(function([latitude,longitude]){
            return {lat: latitude, lng: longitude};
          })
        let coordsCopy = [...coords];

        ctx5.setState({
          isochroneData: coordsCopy,
          lat: latitude,
          lng: longitude
        });
      }
    }).catch(function(error) {
      console.log('Request failed', error)
    });

    }
  }


  render() {
    const ctx = this;

    var markerList = ctx.state.locations.map(function(data, key) {
      var markerType;
      if (data.bortleScale === 'C1 (Ciel excellent)') {
        markerType = black;
      } else if (data.bortleScale === 'C2 (Ciel vraiment noir)') {
        markerType = darkBlue;
      } else if (data.bortleScale === 'C3 (Ciel rural)') {
        markerType = lightBlue;
      } else if (data.bortleScale === 'C4 (Transition rural-urbain)') {
        markerType = green;
      } else if (data.bortleScale === 'C5 (Ciel péri-urbain)') {
        markerType = yellow;
      } else if (data.bortleScale === 'C6 (Ciel de banlieue)') {
        markerType = orange;
      } else if (data.bortleScale === 'C7 (Transition banlieue-ville)') {
        markerType = red;
      } else if (data.bortleScale === 'C8 (Ciel de ville)') {
        markerType = pink;
      } else if (data.bortleScale === 'C9 (Ciel de centre-ville)') {
        markerType = white;
      }
      return (
        <Marker
          key={key}
          title={data.locationName}
          name={data.locationName}
          icon={markerType}
          anchorPoint={{
            x: -5,
            y: -5
          }}
          position={{
            lat: data.latitude,
            lng: data.longitude
          }}
          onClick={() => ctx.toggleDescription(data)}
        />
      );
    });

    var { google } = this.props;

    return (
      <div
        id="wrapper"
        style={{
          height: '100vh'
        }}
      >
        {this.state.connection ? null : (
          <div>
            <Modal isOpen={this.state.modal}>
              <ModalBody className="alertSignIn">
              We request you to login to access this feature.
              </ModalBody>
              <ModalFooter className="alertButton">
                <Link to="/signin">
                  <Button color="primary">Ok</Button>{' '}
                </Link>
                <Button color="secondary" onClick={() => this.setState({ modal: false })}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}

        {this.props.isAuthenticated ? (
          <CollectionCreateForm
            clickLatLng={this.state.clickLatLng}
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            onCreate={this.handleCreate}
          />
        ) : null}
        <Map
          onClick={this.onMapClick}
          google={this.props.google}
          style={style}
          styles={styles}
          disableDefaultUI={true}
          zoomControl={true}
          streetViewControl={false}
          initialCenter={{ lat: this.state.lat, lng: this.state.lng }}
          center={{ lat: this.state.lat, lng: this.state.lng }}
          gestureHandling={'greedy'}
          mapTypeControl={true}
          mapTypeControlOptions= {{
            style: google.maps.MapTypeControlStyle.DEFAULT,
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: ['roadmap', 'satellite']
          }}
          >
          {markerList}


          {this.state.navGeoStatus ? (
            <Marker
              title="You are here"
              icon={userMarker}
              position={{
                lat: this.state.navigatorLat,
                lng: this.state.navigatorLng
              }}
              onClick={() => this.launchIsochrone(this.state.navigatorLat,this.state.navigatorLng)}
            />
          ) : null}

        {(this.state.isochroneData !== null) &&
          <Polygon
            paths={this.state.isochroneData}
            strokeColor="#028090"
            strokeOpacity={1}
            strokeWeight={1}
            fillColor="#028090"
            fillOpacity={0.4}
          />
        }

        </Map>

        <NavigationBar
          refreshMarker={this.getMarker}
          displayFavoriteParent={this.displayFavorite}
        />

        {this.state.showDescription ? (
          <Description
            isLiked={this.state.isLiked}
            favoriteLiked={this.state.favoriteLiked}
            weatherDatas={this.state.weatherDatas}
            userId={this.props.user.id}
            addFavoriteParent={this.addFavorite}
            data={this.state.data}
            toggleForecast={this.toggleForecast}
            toggleWidgetBbc={this.toggleWidgetBbc}
            closeFunction={this.closeWindow}
            launchIsochrone={this.launchIsochrone}
          />
        ) : null}

        {this.state.showFavorite ? (
          <Favoris
            weatherDatas={this.state.weatherDatas}
            userId={this.props.user.id}
            closeFunction={this.closeWindow}
          />
        ) : null}

        <FontAwesomeIcon
          className="mainMapLegend"
          icon={faAtlas}
          onClick={()=>this.displayLegend()}
        />

        <Moon
          moonPhase={this.state.moonPhase}
          ageMoon={this.state.ageMoon}
          angularDiameter={this.state.angularDiameter}
          distanceMoonEarth={this.state.distanceMoonEarth}
          moonIllumination={this.state.moonIllumination}
        />

        {!this.state.legendhidden ? (
          <MapLegend closeLegend={this.closeLegend} />
        ) : null}

        {this.state.showForecast ? (
          <WeatherForecast
            isShown={this.state.showForecast}
            data={this.state.data}
            returnToDescriptionFromForecast={
              this.returnToDescriptionFromForecast
            }
            closeFunction={this.closeWindow}
          />
        ) : null}
        {this.state.showPopUpBbc ? (
          <WidgetBlablaCar
            data={this.state.data}
            returnToDescriptionFromWidgetBbc={
              this.returnToDescriptionFromWidgetBbc
            }
          />
        ) : null}

      </div>
    );
  }
}

// Api google maps
const api = 'AIzaSyAIQZSE4hWZYz9YcyNuTCSjjs6j3jObME0';

// Custom map style
const styles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#242f3e'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#746855'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#242f3e'
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#d59563'
      }
    ]
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#d59563'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#263c3f'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#6b9a76'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#38414e'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#212a37'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9ca5b3'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#746855'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#1f2835'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#f3d19c'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#2f3948'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#d59563'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#17263c'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#515c6d'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#17263c'
      }
    ]
  }
];

const style = {
  width: '100vw',
  minHeight: '100vh'
};


/* Function that establishes correpondance between states in store and properties of presentation component */
/* This redux function collects information located in store which has in turn been collected from the respective reducers. */
/* This includes coordinates of city searched by user. */
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    searchCoordinates: state.searchCoordinates,
    triggerCount: state.triggerCount
  };
}

var Wrapper = GoogleApiWrapper({ apiKey: api })(MapContainer);

/* creation of container component */
export default connect(
  mapStateToProps,
  null
)(Wrapper);
