import React from 'react';
import {Button, Form, FormGroup, Input} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../stylesheet/signup.css";
import {Redirect, Link, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import config from "../config/environment.js";

/* npm package that can be used to verify field format */
var passwordValidator = require('password-validator');

/* Component that performs signup of new user */
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password2: '',
      passwordErrorTable: [],
      passwordErrorStatus: false,
      password2ErrorStatus: false,
      errorStyleStatus: false,
      errors: {},
      redirect: false
    };

    this.capturePasswordData = this.capturePasswordData.bind(this);
    this.capturePassword2Data = this.capturePassword2Data.bind(this);
    this.handleClickResetPassword = this.handleClickResetPassword.bind(this);
  }


  /* capturePasswordData captures data entered in password field */
  capturePasswordData(event) {
    /* Prevents default action of page refresh which we can't let happen in React (SPA) */
    event.preventDefault();

    this.setState({password: event.target.value});

  }

  /* capturePassword2Data captures data entered in password2 field */
  capturePassword2Data(event) {
    /* Prevents default action of page refresh which we can't let happen in React (SPA) */
    event.preventDefault();

    this.setState({password2: event.target.value});

  }

  componentDidUpdate(prevState) {
    /* If any of 4 fields are erroneous we update this.state.errorStyleStatus and use it to apply a different style */
    if (this.state.passwordErrorStatus || this.state.password2ErrorStatus) {

      /* if condition on !this.state.errorStyleStatus prevents infinte loop and rerendering */
      if (!this.state.errorStyleStatus) {
        this.setState({errorStyleStatus: true});
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({errors: nextProps.errors})
    }
  }

  /* This fn sends username, email & password info to backend to save into database */
  /* The userid generated by database for this entry is then returned to be used */
  /* as input for redux to be shared through reducer. Fn onLoginClick sends information */
  /* to container component. */
  /* This fn feeds data to backend with fetch function and program redirects user to map */
  handleClickResetPassword(event, signal) {

    /* Prevents default action of page refresh which we can't let happen in React (SPA) */
    event.preventDefault();

    /* creation of schema for password, username & email robustness validation */
    var schemaPassword = new passwordValidator();

    /* Add properties to password validation schema */
    // Minimum length 8
    // Maximum length 100
    // Must have digits
    // Should not have spaces
    // Blacklist these values
    schemaPassword.is().min(8).is().max(100).has().digits().has().not().spaces().is().not().oneOf(['Passw0rd', 'Password123', 'azerty', 'qwerty']);

    var ctx = this;

    var code = event.keyCode || event.which;

        /* If password fulfill defined conditions in schema then test next if condition */
        if (schemaPassword.validate(ctx.state.password)) {

          /* if password & password2 fields match then test next if condition */
          if (ctx.state.password === ctx.state.password2) {

            /* if enter key or signup button(signal==='submitAction') is pressed to submit data then send POST to back-end */
            if (code === 13 || signal === 'submitAction') {

              const user = {
                userId: this.props.match.params.id,
                password: this.state.password,
                password2: this.state.password2
              }

              axios
                .post(`${config.config.urlFetch}/api/users/resetPassword`, user)
                .then((data, history) =>{
                  this.setState({
                    redirect: true
                  })
                }
                )
                .catch(err => console.log(err));

            }/* no else statement for if (code===13 || code===undefined) */

          } else {
            /* password and password2 fields don't match */
            ctx.setState({password2ErrorStatus: true})
          }
        } else {
          // Get a full list of rules which failed for password
          if (!(schemaPassword.validate(ctx.state.password))) {
            ctx.setState({
              passwordErrorTable: schemaPassword.validate(ctx.state.password, {list: true}),
              passwordErrorStatus: true
            })
          }
        }
    }


  render() {

    var passwordErrorPrompt = null;

    if (this.state.passwordErrorStatus) {
      var passwordErrorArray = [];

      for (var i = 0; i < this.state.passwordErrorTable.length; i++) {
        if (this.state.passwordErrorTable[i] === 'min') {
          passwordErrorArray.push("Min length 8 characters");
        } else if (this.state.passwordErrorTable[i] === 'max') {
          passwordErrorArray.push("Max length 100 characters");
        } else if (this.state.passwordErrorTable[i] === 'digits') {
          passwordErrorArray.push("Must contain at least one digit");
        } else if (this.state.passwordErrorTable[i] === 'spaces') {
          passwordErrorArray.push("Must not contain spaces");
        } else if (this.state.passwordErrorTable[i] === 'oneOf') {
          passwordErrorArray.push("Forbidden word used");
        }
      }
      passwordErrorPrompt = passwordErrorArray.toString();
    }


    return (<div className="background">

    {
      this.state.redirect
        ? <Redirect to="/signin"/>
        : null
    }

      <Form className="formSignup">

        <h3 className="signUpText">Reset your password</h3>

        <p className="signupInstruction">Please use only letters of the alphabet and numbers.</p>

        <div className={this.state.errorStyleStatus
            ? "signupFieldsError"
            : "signupFields"}>

          <FormGroup className="passwordSignupForm">
            <Input type="password" name="password" className={this.state.passwordErrorStatus
                ? "passwordSignupInputError"
                : "passwordSignupInput"} placeholder="Please enter your new password" onChange={this.capturePasswordData} onKeyUp={(event) => ((event.keyCode === 13) || (event.which === 13)) && this.handleClickResetPassword}/>
          </FormGroup>

          {
            this.state.passwordErrorStatus
              ? <p className="passwordErrorPromptStyle">{passwordErrorPrompt}</p>
              : null
          }

          <FormGroup className="passwordSignupForm">
            <Input type="password" name="password2" className={this.state.password2ErrorStatus
                ? "passwordSignupInputError"
                : "passwordSignupInput"} placeholder="Please confirm your password" onChange={this.capturePassword2Data} onKeyUp={(event) => ((event.keyCode === 13) || (event.which === 13)) && this.handleClickResetPassword}/>
          </FormGroup>

          {
            this.state.password2ErrorStatus
              ? <p className="passwordErrorPromptStyle">Passwords entered in both fields must be identical.</p>
              : null
          }

          <FormGroup className="signupButtonForm">
            <Link to="/signin">
              <Button type="button" className="cancelButton">Cancel</Button>
            </Link>
            <Button type="submit" className="submit" onClick={(event) => this.handleClickResetPassword(event, 'submitAction')}>Validate</Button>
          </FormGroup>

        </div>

      </Form>

    </div>);
  }
}

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  null
)(withRouter(ResetPassword));