import React from 'react';
import { Button } from 'reactstrap';
import '../stylesheet/landingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkedAlt, faCloudSun, faTree, faCar, faMoon } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';

class LandingPage extends React.Component {
  render() {
    const isAuthenticated = this.props.auth.isAuthenticated;

    return (
     <React.Fragment>
      <div className="landingPageContainer">
        <div className={isAuthenticated ? "homeTopRowConnected":"homeTopRow"}>
          {
            isAuthenticated
              ? null : 
              <div className="landingButtonsDiv">
                <Link to = "/signin"><Button className = "btn-Home-Sign1">Signin</Button></Link>
                <Link to = "/signup"> <Button className = "btn-Home-Sign2">Signup</Button></Link>
              </div>
          }
        </div>

        <div className="homeContent">
          <h1 className="homeTitle">Dark Sky Map</h1>
          <h2 className="homeSubTitle">Discover and share the best places to observe the night sky.</h2>
          <h3 className="homeText">Mapping the best locations to observe the night sky powered by our community.</h3>
          <Link to = "/map"><Button className="btnHomeDiscover">Discover</Button></Link>
          <a href='https://www.meetstargazers.com/' target='_blank' rel='noopener noreferrer'><Button className="msgButton">Our Mobile App: Meet Star Gazers</Button></a>
        </div>

      </div>

      <div className="appStoreSection">
        <a
          href="https://play.google.com/store/apps/details?id=com.meetstargazers.application"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="handle" src="https://meetstargazers.s3.eu-west-3.amazonaws.com/images/GooglePlay.png" alt="Google Play Badge"/>
        </a>
        <a
          href="https://apps.apple.com/us/app/meet-star-gazers/id1496751760?mt=8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="handle" src="https://meetstargazers.s3.eu-west-3.amazonaws.com/images/AppStore.png" alt="App Store Badge"/>
        </a>
      </div>

      <div className="introVideo">
        <iframe 
          src="https://www.youtube.com/embed/liY0x_9uyUg?controls=0" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
          className="presentationVideo">
        </iframe>
      </div>

      <div className="homeButtonSection">
         <div className="homeButtonContainer">
           <Link to="/nightSkyBrightness"><button className="componentBridge">Light Pollution Map</button></Link>
         </div>
         <div className="homeButtonContainer">
           <Link to="/cloudsprecipitation"><button className="componentBridge">Clouds & Precipitation</button></Link>
         </div>
         <div className="homeButtonContainer">
           <Link to="/moonengine"><button className="componentBridge">Moon n + 29 days</button></Link>
         </div>
      </div>

      <div className="featureSection">

        <div className="firstfeatureContainer">
          <div className="mapFeature">
            <FontAwesomeIcon icon={faMapMarkedAlt} className="mapFeatureIcon"/>
            <h5 className="mapFeatureHeading">Maps</h5>
            <ul className="cartographyList">
              <li>Qualification of astronomical observation sites</li>
              <li>Light Pollution Map (ISTIL)</li>
              <li>Human Footprint Map (GIBS/NASA)</li>
              <li>Geolocation (only at the request of the user)</li>
              <li>Search by city</li>
              <li>Create a location with a simple click on the map</li>
              <li>Bed and breakfasts for astronomy</li>
              <li>Astronomical Observatories</li>
            </ul>
          </div>
        </div>

        <div className="firstfeatureContainer">
          <div className="weatherFeature">
            <FontAwesomeIcon icon={faCloudSun} className="meteoIcon"/>
            <h5 className="meteoFeatureHeading">Weather</h5>
            <ul className="meteoList">
              <li>Current weather for each location</li>
              <li>Forecast weather n + 4 days</li>
              <li>Weather Engine</li>
              <li>Create your list of favorite locations and monitor their weather conditions</li>
            </ul>
          </div>
        </div>

        <div className="secondfeatureContainer">
          <div className="riceFeature">
            <FontAwesomeIcon icon={faTree} className="riceFeatureIcon"/>
            <h5 className="riceFeatureHeading">Dark Sky Reserves</h5>
            <ul className="riceFeatureList">
              <li>Dark Sky Communities (IDA)</li>
              <li>Dark Sky Parks (IDA)</li>
              <li>Dark Sky Reserves (IDA)</li>
              <li>Dark Sky Sanctuaries (IDA)</li>
            </ul>
          </div>
        </div>

        <div className="secondfeatureContainer">
          <div className="transportFeature">
            <FontAwesomeIcon icon={faCar} className="transportFeatureIcon"/>
            <h5 className="transportFeatureHeading">Travel</h5>
            <ul className="transportFeatureList">
              <li>BlaBlaCar Carpooling</li>
              <li>Driving Directions</li>
              <li>30 min Isochrone</li>
            </ul>
          </div>
        </div>

        <div className="secondfeatureContainer">
          <div className="lunarFeature">
            <FontAwesomeIcon icon={faMoon} className="lunarFeatureIcon"/>
            <h5 className="lunarFeatureHeading">Lunar Information</h5>
            <ul className="lunarFeatureList">
              <li>Lunar Ephemerides of the day</li>
              <li>Lunar Ephemerides n + 30 days</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="subsidiaries">
        <a href="https://podcast.meetstargazers.com" target="_blank" rel="noopener noreferrer">
          <button className="subsidiaryButton">Podcast</button>
        </a>
        <a href="https://blog.meetstargazers.com" target="_blank" rel="noopener noreferrer">
          <button className="subsidiaryButton">Blog</button>
        </a>
      </div>

      <div className="socialMedia">
        <a href="https://www.facebook.com/MeetStarGazers" target="_blank" rel="noopener noreferrer">
          <img src="https://meetstargazers.s3.eu-west-3.amazonaws.com/images/facebook.svg" alt="Facebook Page" className="handle"/>
        </a>
        <a href="https://twitter.com/MeetStarGazers" target="_blank" rel="noopener noreferrer">
          <img src="https://meetstargazers.s3.eu-west-3.amazonaws.com/images/twitter.svg" alt="Twitter Handle" className="handle"/>
        </a>
        <a href="https://www.instagram.com/meetstargazers" target="_blank" rel="noopener noreferrer">
          <img src="https://meetstargazers.s3.eu-west-3.amazonaws.com/images/instagram.svg" alt="Instagram Handle" className="handle"/>
        </a>
      </div>

      <div className="partnerSection">
        <h5 className="partnerSectionHeading">Our partners</h5>
        <div className="partnerLinksDiv">
          <a href="https://saf-astronomie.fr" target="_blank" rel="noopener noreferrer">
            <img className="safLogo" src={require('../images/SAF_logo.png')} alt="French Astronomical Society Logo"/>
          </a>
        </div>
      </div>

      <div className="bottomRow">
        <div className="bottomAbout">
          <h6 className="bottomHeading">Dark Sky Map</h6>
          <p className="missionDescriptionStyle">My ambition is to make astronomy accessible to everyone. In this quest, I invite you to explore a map that helps you find the best places to observe the night sky as well as a map of light pollution as a decision making tool.</p>
        </div>
        <div className="bottomContact">
          <span className="spanEmail"><FontAwesomeIcon icon={faEnvelope} /><a className="pEmail" href="mailto:contact@darkskymap.com">contact@darkskymap.com</a></span>
        </div>
      </div>

     </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

/* creation of container component */
export default connect(
    mapStateToProps,
    null
)(LandingPage);
