import React, {Component} from 'react';
import config from "../config/environment.js";
import {Button, Container, Row, Col} from 'reactstrap';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import { Spin, Icon } from 'antd';

/* This component displays user's favorite locations */
class Favoris extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: [],
      favoritesWeatherDatas: [],
      loading: true
    };
    this.closeComponent = this.closeComponent.bind(this);
    this.deleteFavorite = this.deleteFavorite.bind(this);

  }

  closeComponent() {
    this.props.closeFunction();
  }

  componentWillMount() {
    const ctx = this;
    fetch(`${config.config.urlFetch}/api/users/favorites`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'userId=' + this.props.userId
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      var userFavorites = data.favorite
      ctx.setState({favorites: userFavorites})
      for (var i = 0; i < data.favorite.length; i++) {

        userFavorites.map((favorite, i) => {
          fetch(`${config.config.urlFetch}/api/locations/getLocationWeatherInfos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'latitude=' + favorite.latitude + '&longitude=' + favorite.longitude
          }).then(function(response) {
            return response.json();
          }).then(function(weatherData) {
            var favorites = [...ctx.state.favorites];
            favorites[i].weatherDatas = weatherData;
            ctx.setState({favorites, loading: false})
          });
        })
      }

    });

  }

  deleteFavorite(locationId) {
    const ctx = this;
    fetch(`${config.config.urlFetch}/api/users/deletefavorite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'userId=' + this.props.userId + '&locationId=' + locationId
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      const targetLocation = function(locId) {
        return locId === locationId;
      }
      let favoriteCopy = [...ctx.state.favorites];
      for (let i = 0; i < favoriteCopy.length; i++) {
        if (favoriteCopy[i]._id === locationId) {
          favoriteCopy.splice(i, 1)
          ctx.setState({favorites: favoriteCopy})
        }
      }
    });
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 48 }} spin />;

    var ctx = this;
    var favoritesList = ctx.state.favorites.map(function(data, i) {

      if (data.weatherDatas && data.weatherDatas.weather && data.weatherDatas.weather[0]) {
        return (<Col className="favItem" xs="11" sm="8" md={{
            size: 8
          }} key={i}>{data.locationName}
          <h6 className="favFont">Current weather</h6>
          <img alt="" src={"https://openweathermap.org/img/w/" + data.weatherDatas.weather[0].icon + ".png"}/>
          <p>{data.weatherDatas.weather[0].description}, {data.weatherDatas.main.temp}
            °C</p>
          <FontAwesomeIcon onClick={() => ctx.deleteFavorite(data._id)} className="iconStyle" icon={faTimesCircle}/>
        </Col>)
      }
    })

    return (<div>

      <Container className="favContainerMap">

        <Row className="main-block">

          <Col className="main" xs="11" sm="8">My favourites</Col>

          {
            this.state.loading
            ?<Col className="favItem" xs="11" sm="8" md={{
                size: 8
              }}>
              <Spin size='large' indicator={antIcon} />
            </Col>
            :null
          }
          {favoritesList}

          <Col className="favItem" xs="11" sm="8">
            <Link to="/map">
              <Button outline onClick={() => this.closeComponent()} className="backButton">Back to map</Button>
            </Link>
          </Col>

        </Row>

      </Container>

    </div>);
  }
}

const mapStateToProps = state => ({
  userId: state.auth.user.id
});

export default connect(
  mapStateToProps,
  null
)(Favoris);
