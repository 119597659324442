import React from "react";
import { Navbar, NavbarText } from "reactstrap";
import { NavLink } from "react-router-dom";

function CloudsNavBar() {
  return (
    <div>
      <Navbar style={{ backgroundColor: "#028090" }} fixed="top">
        <NavbarText
          style={{ color: "#ffffff", fontFamily: "Arial", fontSize: "3vh" }}
        >
          Clouds & Precipitation
        </NavbarText>
        <NavLink style={{ color: "#ffffff", fontFamily: "Arial" }} to="/">
          Home
        </NavLink>
      </Navbar>
    </div>
  );
}

export default CloudsNavBar;
