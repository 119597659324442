import React from 'react';
import { Rate } from 'antd';
import 'antd/dist/antd.css';
import {connect} from 'react-redux';
import config from "../config/environment"

// component which captures and displays ratings through star icons
// (average and vote counts calculations are done in backend).

class Rater extends React.Component {
  constructor(){
    super()
    this.transferRatingDataChild= this.transferRatingDataChild.bind(this)
  }

  state = {
    value: 0
  }

transferRatingDataChild(data){
this.props.transferRatingData(data)
}

/*when the state changes, the new value is sent to the backend
to store the user's rating of the location*/
  componentDidUpdate(prevState) {
var ctx = this
    if (prevState.value !== this.state.value) {

    var data = {userId: this.props.user.id, locationId: this.props.locationId, value: this.state.value}


fetch(`${config.config.urlFetch}/api/locations/locationVote`, {
  method: 'POST',
  body: JSON.stringify(data),
  headers:{
    'Content-Type': 'application/json'
  }
}).then(res => res.json())
.then(function(data) {

  ctx.transferRatingDataChild(data)
})
.catch(error => console.error('Error:', error));
    }
  }

  handleChange = value => {
    this.setState({ value: value })
  }

  render() {
    const { value } = this.state;
    return (
        <Rate onChange={this.handleChange} value={value} />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  }
}

export default connect(
  mapStateToProps,
  null
)(Rater)
