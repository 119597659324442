import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem, } from 'reactstrap';
import { Spin } from 'antd';
import '../stylesheet/moonengine.css';
import config from "../config/environment.js";
import fullMoon from '../images/moon_phases/full.jpg';
import firstQuarter from '../images/moon_phases/first_quarter.jpg';
import newMoon from '../images/moon_phases/new.jpg';
import thirdQuarter from '../images/moon_phases/third_quarter.jpg';
import waningCrescent from '../images/moon_phases/waning_crescent.jpg';
import waningGibbous from '../images/moon_phases/waning_gibbous.jpg';
import waxingCrescent from '../images/moon_phases/waxing_crescent.jpg';
import waxingGibbous from '../images/moon_phases/waxing_gibbous.jpg';

class MoonEngine extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.scrollToTopOfPage = this.scrollToTopOfPage.bind(this);


    this.state = {
      isOpen: false,
      moonInfoTable: [],
      loading: true,
    };

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  async componentDidMount() {
    /* Moon data Farmsense API request */
    /* We collect moonPhase, ageMoon, angularDiameter, distanceMoonEarth & */
    /* moonIllumination values which are states in MoonEngine component */
    var unixTimeStamp = Math.round((new Date()).getTime() / 1000);

    var ctx = this;
    for (var i = 0; i < 30; i++) {
      await fetch(`${config.config.urlFetch}/api/locations/getMoonData`, {
        method: 'POST',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: 'unixTimeStamp='+(unixTimeStamp+(i*86400)),
      }).then(function(response) {
        return response.json();
      }).then(function(moonDatas) {

       var moonInfoTableCopy = [...ctx.state.moonInfoTable];

        moonInfoTableCopy.push({
          date: moonDatas[0].TargetDate,
          moonPhase: moonDatas[0].Phase,
          ageMoon: (moonDatas[0].Age).toFixed(2),
          angularDiameter: (moonDatas[0].AngularDiameter).toFixed(2),
          distanceMoonEarth: (moonDatas[0].Distance).toFixed(2),
          moonIllumination: Math.round(((moonDatas[0].Illumination).toFixed(2))*100),
        });

        ctx.setState({
          moonInfoTable : moonInfoTableCopy
        });

        if (ctx.state.moonInfoTable.length === 30) {
          ctx.setState({
            loading : false,
          });
        }

      }).catch(function(error) {
        console.log('Request failed', error);
      });

    }

  }

  /* This function brings/scrolls the user to top of page. */
  scrollToTopOfPage() {
    window.scrollTo(0, 0);
  }

  render() {
    var data;

    if (this.state.loading) {
      data = <Spin size="large" tip="Loading..." className="moonEngineSpinner"/>
    } else {
      data =
              <div className="moonInfoSection">

              {
                this.state.moonInfoTable.length === 30 ?

                this.state.moonInfoTable.map((moonInfoUnit, key) => {

                  var moonPic = {};
                  if (this.state.moonInfoTable[key].moonPhase === 'Full Moon') {
                    moonPic = fullMoon;
                  } else if (this.state.moonInfoTable[key].moonPhase === 'Waning Gibbous') {
                    moonPic = waningGibbous;
                  } else if (this.state.moonInfoTable[key].moonPhase === 'Waning Crescent') {
                    moonPic = waningCrescent;
                  } else if (this.state.moonInfoTable[key].moonPhase === 'Waxing Gibbous') {
                    moonPic = waxingGibbous;
                  } else if (this.state.moonInfoTable[key].moonPhase === 'Waxing Crescent') {
                    moonPic = waxingCrescent;
                  } else if (this.state.moonInfoTable[key].moonPhase === 'New Moon') {
                    moonPic = newMoon;
                  } else if (this.state.moonInfoTable[key].moonPhase === 'Dark Moon') {
                    moonPic = newMoon;
                  } else if (this.state.moonInfoTable[key].moonPhase === '3rd Quarter') {
                    moonPic = thirdQuarter;
                  } else if (this.state.moonInfoTable[key].moonPhase === '1st Quarter') {
                    moonPic = firstQuarter;
                  }

                  return (
                    <div className="moonInfoContainer" key={key}>
                      <div className="moonInfoUnit">
                        <img className="moonPicEngineStyle" src={this.state.moonInfoTable[key] ? moonPic : null} alt="moonPic" />
                        <p className="moonEngineText">Date: {this.state.moonInfoTable[key] ? `${new Date((this.state.moonInfoTable[key].date)*1000).getDate()}.${(new Date((this.state.moonInfoTable[key].date)*1000).getMonth())+1}.${new Date((this.state.moonInfoTable[key].date)*1000).getFullYear()}`: null}</p>
                        <p className="moonEngineText">Lunar Phase: {this.state.moonInfoTable[key] ? this.state.moonInfoTable[key].moonPhase : null}</p>
                        <p className="moonEngineText">Visibility: {this.state.moonInfoTable[key] ? this.state.moonInfoTable[key].moonIllumination : null} %</p>
                        <p className="moonEngineText">Age: {this.state.moonInfoTable[key] ? this.state.moonInfoTable[key].ageMoon : null} days</p>
                        <p className="moonEngineText">Angular Diameter: {this.state.moonInfoTable[key] ? this.state.moonInfoTable[key].angularDiameter : null} degrees</p>
                        <p className="moonEngineText">Earth-Moon Distance: {this.state.moonInfoTable[key] ? this.state.moonInfoTable[key].distanceMoonEarth : null} km</p>
                      </div>
                    </div>
                  )
                })
                  : null
              }

              </div>

    }

    return (
      <div>

        <Navbar className="moonEngineNavBar" light expand="md">
          <NavbarBrand id="moonEngineHeading">Moon n + 29 days</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link to="/" className="homeLinkMoonEngine">Home</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        {data}

        {
          this.state.moonInfoTable.length === 30 ?
          <div className="moonEngineFooterDiv">
            <Link to="/"><button className="moonEngineFooter">Home</button></Link>
            <button onClick={this.scrollToTopOfPage} className="moonEngineFooter">Top</button>
          </div> : null
        }


      </div>
    );
  }

  }

  export default (MoonEngine);
