import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledTooltip,
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";

class NavBarLightPollutionAtlas extends Component {
  constructor(props){
    super(props);
    this.state ={
      isOpen: false,
    }
  }

  /* This function toggles the collapse/uncollapse of navbar. */
  toggleNavBar = () => {
   this.setState({
     isOpen: !this.state.isOpen
   });
  }


  render(){

    return(
      <div>
        <Navbar className="lightPollutionAtlasNavbar" light expand="md">
          <NavbarBrand id="lightPollutionAtlasHeading">World Atlas Night Sky Brightness</NavbarBrand>
          <FontAwesomeIcon icon={faMapMarkerAlt} id="locateMeToolTip" className="locateMe" onClick={this.props.geoLocateMe}/>
          <UncontrolledTooltip placement="bottom" target="locateMeToolTip">Locate your position</UncontrolledTooltip>
          <NavbarToggler onClick={this.toggleNavBar} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link to="/map" className="mapLink">Map</Link>
              </NavItem>
              <NavItem>
                <Link to="/" className="homeLinkLightPollutionAtlas">Home</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
};

export default (NavBarLightPollutionAtlas);