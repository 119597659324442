import React from 'react';
import {Button, Form, FormGroup, Input} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../stylesheet/signin.css";
import { Link, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {loginUser} from '../actions/authActions';
import axios from 'axios';
import config from "../config/environment.js";

/* npm package that can be used to verify field format */
var passwordValidator = require('password-validator');

/* captureEmailData captures data entered in email field */
/* capturePasswordData captures data entered in password field */
/* handleClickSignIn sends data to backend with fetch function and verifies if user exists in database and if yes program directs user to map */

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      userNotFound: false,
      errors: {},
      confirmedEmail: {},
      emailSend: false
    }
    this.captureEmailData = this.captureEmailData.bind(this);
    this.handleClickSubmit = this.handleClickSubmit.bind(this);
  }

  captureEmailData(event) {
    event.preventDefault();
    this.setState({email: event.target.value});

  }

  capturePasswordData(event) {
    event.preventDefault();
    this.setState({password: event.target.value});

  }

  handleClickSubmit = (event, submitSignal) => {
    event.preventDefault();

    /* creation of schema for password & email */
    var schemaEmailField = new passwordValidator();



    /* To ensure email field is not empty before submission */
    // Minimum length 7
    // Maximum length 100
    // Should not have spaces
    schemaEmailField.is().min(7).is().max(100).has().not().spaces()

    var code = event.keyCode || event.which;

    /* email and password inputs must conform to conditions defined above and email input must contain @ character */
    /* if these conditions are satisfied the program goes on to next set of conditions */
    if (schemaEmailField.validate(this.state.email) && ((this.state.email.indexOf("@")) > -1)) {
      /* code===13 OR code===undefined ensures that enter key or connexion button is pressed before making fetch call to backend */
      if (code === 13 || submitSignal === 'submitAction') {

        const userData = {
          email: this.state.email,
        }


        axios
          .post(`${config.config.urlFetch}/api/users/forgotPassword`, userData)
          .then(
            (response) => {
              if(response.data.user) {
                const confirmedEmail = response.data.user.email;
                // const userId = response.data.user._id;
                /* Nested fetch to send email to user to define a new password */
                fetch(`https://hooks.zapier.com/hooks/catch/4097564/pcslii/?email=${response.data.user.email}&userId=${response.data.user._id}`).then(function(response) {
                  return response.json();
                }).then(function(data) {
                  console.log('data:', data);
                }).catch(function(error) {
                  console.log('Request failed', error)
                })

              this.setState({
                emailSend: true,
                confirmedEmail: confirmedEmail
              })
            } else if(response.data.error) {
              console.log('error: ', response.data.error);
              this.setState({
                userNotFound: true,
                error: response.data.error
              })
            }
            }
          )
          .catch(err => console.log(err));
      }
    }
  }

  render() {

    return (
      <div>
        <div className="background">
          {
            this.state.emailSend
            ?<div className="formSignin">
            <h2 className="signInTitle">An email has been sent to {this.state.confirmedEmail}</h2>
            <h3 className="signInTitle">Please click on the link in the email to reset your password.</h3>
            <Link to="/signin">
              <Button type="button" className="cancelButton1">Back</Button>
            </Link>
            </div>
            :<Form className="formSignin">

              <h2 className="signInTitle">Forgot your password ?</h2>
              <h3 className="signInTitle">Please enter your email</h3>

              <FormGroup className="emailFormGroup">
                    <Input type="email" name="email" onChange={this.captureEmailData} onKeyUp={this.handleClickSignIn} className="email" placeholder="Email"/>
              </FormGroup>

              {
                this.state.userNotFound
                  ? <p className="signInErrorPromptStyle">{this.state.error}</p>
                  : null
              }

              <FormGroup className="signinButtonForm">
                <Link to="/signin">
                  <Button type="button" className="cancelButton1">Cancel</Button>
                </Link>
                <Button type="submit" onClick={(event) => this.handleClickSubmit(event, 'submitAction')} className="submit1" color="secondary">Enter</Button>
              </FormGroup>

            </Form>
          }
        </div>
      </div>
    );
  }
};

// function mapDispatchToProps(dispatch) {
//   return {
//     onLoginClick: function(userId) {
//         dispatch( {type: 'display', userId:userId} )
//     }
//   }
// }
//
// export default connect(
//     null,
//     mapDispatchToProps
// )(Signin);

ForgotPassword.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth, errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(withRouter(ForgotPassword));
