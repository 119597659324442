import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import config from "../config/environment.js";
import '../stylesheet/weatherforecast.css';

/* wheather forecast opens when click on meteo previsionnelle button on */
/* description component */
class weatherForecast extends Component {
  constructor(props){
    super(props);
    this.state = {
      weatherForecastData: [],
    }
  };



/* close description, details and forecast in Map component */
  closeAllComponents = () => {
    this.props.closeFunction();
  }

/* close weather forecast and back to description component */
toggleBackToDescription = (data) => {
    this.props.returnToDescriptionFromForecast(data);
    }

/* get weather forecast data from openweathermap api*/

  componentDidMount(){
    fetch(`${config.config.urlFetch}/api/locations/weatherForecast`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'latitude=' + this.props.data.latitude + '&longitude=' + this.props.data.longitude
    }).then(response => {
      return response.json();
    }).then(data => {
      var weatherForecastDataCopy = [...this.state.weatherForecastData]
/* forecast data received from openweathermap api is filtered to retain */
/* data corresponding to 12h00 & 21h00 */
        var newTablo = data.list.map((days, i) =>{
        var hours = days.dt_txt;
        var hoursOk = hours.slice(11,13);
        var date = hours.slice(8,10);
        var month = hours.slice(5,7);
        var year = hours.slice(0,4);
        // var today = new Date();
        // var dayToday = String(today.getDate());
        if(((hoursOk === "12") || (hoursOk === "21"))) {
          weatherForecastDataCopy.push({
            hours: hoursOk,
            date: date,
            month: month,
            year: year,
            description: days.weather[0].description,
            icon: days.weather[0].icon,
            cloudCover: days.clouds.all,
            temp: days.main.temp,
            humidity: days.main.humidity,
            windSpeed: days.wind.speed,
          })
        }

      })

      this.setState({
        weatherForecastData: weatherForecastDataCopy,
      })

    }).catch(error => {
      console.error(error);
    });

  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isShown}>
          <ModalHeader>
            <Button outline color="secondary" className="buttonDescStyleUnsW" onClick={() => this.toggleBackToDescription(this.props.data)}>Current Weather</Button>{' '}
            <Button outline color="secondary" className="buttonStyleSelecW">Forecasted Weather</Button>
          </ModalHeader>
          <ModalBody>
            <div className="dayForecast">
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].date : null}/{this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].month : null}/{this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].year : null} à {this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[0] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[0].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[0] ? this.state.weatherForecastData[0].windSpeed : null} m/s</p>
            </div>
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].date : null}/{this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].month : null}/{this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].year : null} à {this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[1] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[1].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[1] ? this.state.weatherForecastData[1].windSpeed : null} m/s</p>
            </div>
          </div>

          <div className="dayForecast">
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].date : null}/{this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].month : null}/{this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].year : null} à {this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[2] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[2].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[2] ? this.state.weatherForecastData[2].windSpeed : null} m/s</p>
            </div>
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].date : null}/{this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].month : null}/{this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].year : null} à {this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[3] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[3].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[3] ? this.state.weatherForecastData[3].windSpeed : null} m/s</p>
            </div>
          </div>

          <div className="dayForecast">
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].date : null}/{this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].month : null}/{this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].year : null} à {this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[4] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[4].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[4] ? this.state.weatherForecastData[4].windSpeed : null} m/s</p>
            </div>
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].date : null}/{this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].month : null}/{this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].year : null} à {this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[5] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[5].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[5] ? this.state.weatherForecastData[5].windSpeed : null} m/s</p>
            </div>
          </div>

          <div className="dayForecast">
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].date : null}/{this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].month : null}/{this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].year : null} à {this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[6] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[6].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[6] ? this.state.weatherForecastData[6].windSpeed : null} m/s</p>
            </div>
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].date : null}/{this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].month : null}/{this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].year : null} à {this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[7] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[7].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[7] ? this.state.weatherForecastData[7].windSpeed : null} m/s</p>
            </div>
          </div>

          <div className="dayForecast">
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].date : null}/{this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].month : null}/{this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].year : null} à {this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[8] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[8].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[8] ? this.state.weatherForecastData[8].windSpeed : null} m/s</p>
            </div>
            <div className="halfDayForecast">
              <p className="dateForecast">{this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].date : null}/{this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].month : null}/{this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].year : null} à {this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].hours : null}:00 UTC</p>
              <img style={{height: 40}} alt="" src={this.state.weatherForecastData[9] ?"https://openweathermap.org/img/w/" + this.state.weatherForecastData[9].icon + ".png" : null}/>
              <p className="weatherdescforecast">Weather conditions: {this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].description : null}</p>
              <p className="weatherdescforecast">Cloud Cover: {this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].cloudCover : null}%</p>
              <p className="weatherdescforecast">Temperature: {this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].temp : null}°C</p>
              <p className="weatherdescforecast">Humidity: {this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].humidity : null}%</p>
              <p className="weatherdescforecast">Wind Speed: {this.state.weatherForecastData[9] ? this.state.weatherForecastData[9].windSpeed : null} m/s</p>
            </div>
          </div>
          </ModalBody>
          <ModalFooter>
            <Button outline color="secondary" className="buttonStyleBack" onClick={() => this.closeAllComponents()}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
};

export default (weatherForecast);