import React from 'react';
import '../stylesheet/atlasLegend.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';

/* Legend of World Atlas for Night Sky Brightness */
class AtlasLegend extends React.Component {
  constructor(props) {
    super(props);
    this.closeLegend = this.closeLegend.bind(this);
  }

  closeLegend() {
    this.props.closeLegend();
  }

  render(){

    return(
      <div className='atlasLegendParent'>
      <FontAwesomeIcon className='legendClose' icon={faTimesCircle} onClick={()=>this.closeLegend()}/>
      <div className='topRow'>
        <p className='legendHeading'>Key : Bortle Scale</p>
      </div>
      <div className='legendContent'>
        <div className='colourColumn'>
        <div className='colourRow'>
          <div className='codeBlack'></div>
          <div className='codeDarkGrey'></div>
          <div className='codeGrey'></div>
        </div>
        <div className='codeDarkBlue'></div>
        <div className='colourRow'>
          <div className='codeBlue'></div>
          <div className='codeLightBlue'></div>
        </div>
        <div className='colourRow'>
          <div className='codeDarkGreen'></div>
          <div className='codeGreen'></div>
        </div>
        <div className='codeYellow'></div>
        <div className='codeOrange'></div>
        <div className='codeRed'></div>
        <div className='colourRow'>
          <div className='codeMagenta'></div>
          <div className='codePink'></div>
        </div>
        <div className='codeWhite'></div>
        </div>
        <div className='bortleColumn'>
          <p className='bortleText'>C1 Excellent dark-sky site</p>
          <p className='bortleText'>C2 Typical truly dark site</p>
          <p className='bortleText'>C3 Rural sky</p>
          <p className='bortleText'>C4 Rural/suburban transition</p>
          <p className='bortleText'>C5 Suburban sky</p>
          <p className='bortleText'>C6 Bright suburban sky</p>
          <p className='bortleText'>C7 Suburban/urban transition</p>
          <p className='bortleText'>C8 City sky</p>
          <p className='bortleText'>C9 Inner-city sky</p>
        </div>
      </div>
      </div>
    )
  }

}

export default (AtlasLegend);
